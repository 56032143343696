export const environment = {
  production: true,
  api: {
    core: {
      uri: 'https://800tfn1.call-metrics.com:9443',
      path: '/api/v1',
    },
  },
  softphone: {
    scheme: 'wss',
    server: '800tfn1.call-metrics.com',
    port: '6061',
    path: '/ws',
    sipDomain: 'tenant_',
  },
  stripe: {
    key: 'pk_test_51MBHtwJ7lrcvxsq0axhu4LgQrsOUnCAGD5YufLsHxGQFstNYjwKLhiJchFQLMwK6T259MyquWETJWTKWpOLvU8rP00Z5XnIbKv',
  },
};
