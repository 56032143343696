export const DISABLED_ALL_DAYS = [0, 1, 2, 3, 4, 5, 6];

export enum FilterDate {
  range = 'range',
  today = 'today',
  yesterday = 'yesterday',
  thisWeek = 'thisWeek',
  lastWeek = 'lastWeek',
  lastMonth = 'lastMonth',
  thisMonth = 'thisMonth',
  last7 = 'last7',
  last30 = 'last30',
  last60 = 'last60',
  month = 'month',
}

// filter params
export const DateOptions = [
  { value: FilterDate.range, text: 'Is Between' },
  { value: FilterDate.today, text: 'Is Today' },
  { value: FilterDate.yesterday, text: 'Is Yesterday' },
  { value: FilterDate.thisWeek, text: 'Is This Week' },
  { value: FilterDate.lastWeek, text: 'Is Last Week' },
  { value: FilterDate.thisMonth, text: 'Is This Month' },
  { value: FilterDate.lastMonth, text: 'Is Last Month' },
  { value: FilterDate.last7, text: 'Is Last 7 Days' },
  { value: FilterDate.month, text: 'Is Month' },
  { value: FilterDate.last30, text: 'Is Last 30 Days' },
  { value: FilterDate.last60, text: 'Is Last 60 Days' },
];

export const DateButtonOptions = [
  { value: FilterDate.today, text: 'Today' },
  { value: FilterDate.yesterday, text: 'Yesterday' },
  // {value: FilterDate.thisWeek, text: 'This Week'},
  { value: FilterDate.lastWeek, text: 'Last Week' },
  { value: FilterDate.thisMonth, text: 'This Month' },
  { value: FilterDate.lastMonth, text: 'Last Month' },
  { value: FilterDate.last7, text: 'Last 7 Days' },
  { value: FilterDate.last30, text: 'Last 30 Days' },
  { value: FilterDate.last60, text: 'Last 60 Days' },
  { value: FilterDate.month, text: 'Month' },
  { value: FilterDate.range, text: 'Between' },
];

export enum CMSUserType {
  superAdmin = 'super_admin',
  primaryAdmin = 'primary_admin',
  administrator = 'administrator',
  normalUser = 'normal_user',
}

export const AnimationInterval = 1000;
export const NoPermissionAlertInteral = 2000;

export const ROUTE_TO_SIP_URI = 'ROUTE_TO_SIP_URI';

export const RECEIVING_NUMBER_ROUTES = [
  {
    value: ROUTE_TO_SIP_URI,
    desc: 'Route to sip uri',
  },
];

export const USER_TYPE_ADMINISTRATOR = 'ADMINISTRATOR';
export const USER_TYPE_NORMAL_USER = 'NORMAL_USER';

export const PERMISSION_TYPE_ALL = 'PERMITALL';
export const PERMISSION_TYPE_READONLY = 'READONLY';
export const PERMISSION_TYPE_DENY = 'DENY';

export const PERMISSION_READABLE = 'readable';
export const PERMISSION_WRITEABLE = 'writeable';

export const PERMISSION_ID_DENY = 1;
export const PERMISSION_ID_READONLY = 2;
export const PERMISSION_ID_ALL = 3;

export const GUI_VISIBILITY_MATCH = {
  CallLogs: 'Call Logs',
  Dashboard: 'Dashboard',
  NumbersManagement: 'Buy Numbers',
  TrackingNumbers: 'Tracking Numbers',
  Overview: 'Overview',
  TrackingSources: 'Tracking Sources',
  ActivityReports: 'Activity Reports',
  ReceivingNumbers: 'Receiving Numbers',
  SipGateways: 'Sip Gateways',
  Role: 'Manage Roles',
  User: 'Manage Users',
  Customer: 'Manage Customers',
  Billing: 'Manage Billing',
};

export const DATE_FORMAT = [
  { label: 'mm/dd/yyyy', value: 'mm/dd/yyyy' },
  { label: 'mm-dd-yyyy', value: 'mm-dd-yyyy' },
  { label: 'dd/mm/yyyy', value: 'dd/mm/yyyy' },
  { label: 'dd-mm-yyyy', value: 'dd-mm-yyyy' },
  { label: 'yyyy/mm/dd', value: 'yyyy/mm/dd' },
  { label: 'yyyy-mm-dd', value: 'yyyy-mm-dd' },
];

export const WEEKEND_FORMAT = [
  { label: 'Sunday - Saturday', value: 0 },
  { label: 'Monday - Sunday', value: 1 },
];

export const DEFAULT_PRIMARY_ADMIN_PASSWORD = 'default2021!!';

export const CHART_COLORS = [
  '#F31037',
  '#FE604A',
  '#34E391',
  '#8E0DE5',
  '#54D2ED',
  '#0C62F5',
  '#80B4A0',
  '#2B2B8A',
  '#3C2910',
  '#39A0CB',
  '#21553B',
  '#2085F2',
  '#F4ED68',
  '#339CE7',
  '#0446EA',
  '#F158F1',
  '#C52E4C',
  '#B1E47F',
  '#B66FE2',
  '#FC52E3',
  '#91EC09',
  '#360ECD',
  '#BEA056',
  '#136EF4',
  '#D4EC4C',
  '#B4BB13',
  '#EBE172',
  '#64DAEC',
  '#754104',
  '#4B466F',
  '#ACE5D9',
  '#E11FB7',
  '#24D1C3',
  '#983E4A',
  '#297486',
  '#BFDC57',
  '#3C26BB',
  '#33E675',
  '#B189D3',
  '#EFEC0B',
  '#E9CF8C',
  '#4CF68E',
  '#1E3AB9',
  '#2F34F2',
  '#D930FA',
  '#AAFA18',
  '#9DCD94',
  '#E064E3',
  '#C7F27A',
  '#7D2A81',
  '#519979',
  '#906D0E',
  '#8E48EB',
  '#187A6A',
  '#62B182',
  '#2FAB61',
  '#B68109',
  '#F57364',
  '#EFA42E',
  '#C9B678',
  '#904AA0',
  '#31FCF4',
  '#099B7D',
  '#D96520',
  '#D67D27',
  '#3EE015',
  '#75F16A',
  '#38B162',
  '#6E9DF3',
  '#1159AD',
  '#DB870A',
  '#D542CB',
  '#D374B8',
  '#C91AB7',
  '#D927CF',
  '#6ABC86',
  '#CAC74A',
  '#4A6657',
  '#4339EC',
  '#8EBB83',
  '#B1EF8C',
  '#939BCD',
  '#8E6DEE',
  '#4B6BF9',
  '#A5FC46',
  '#75CAA0',
  '#2F4B7D',
  '#90ED07',
  '#4BC23F',
  '#68EFD3',
  '#3B3D56',
  '#4A2715',
  '#25FF7E',
  '#D67758',
  '#A041AC',
  '#251AB9',
  '#6D0666',
  '#D957D2',
  '#39E616',
  '#D7723A',
  '#8055E8',
  '#83EB04',
  '#4C3F95',
  '#1EDF16',
  '#C5115D',
  '#667349',
  '#BC3613',
  '#9534F1',
  '#B975BB',
  '#8BFA79',
  '#400B1C',
  '#B721F7',
  '#D430B0',
  '#999483',
  '#00EFD7',
  '#E0BF7A',
  '#FF778D',
  '#5E8466',
  '#3041C3',
  '#213DD8',
  '#06543C',
  '#7FC809',
  '#1BA1E2',
  '#CCFEC8',
  '#3B8FEE',
  '#AF1506',
  '#99CE44',
  '#68F7E4',
  '#694F9E',
  '#BABF72',
  '#754088',
  '#B051C7',
  '#F45DCC',
  '#069690',
  '#196502',
  '#6DD537',
  '#259B12',
  '#6774C1',
  '#BA7B19',
  '#4E9BF9',
  '#C7838C',
  '#D68911',
  '#68BDD4',
  '#2FC8FA',
  '#571164',
  '#AE783B',
  '#DFCC00',
  '#77D40D',
  '#31139F',
  '#732557',
  '#F87BA8',
  '#39D93F',
  '#BC136A',
  '#3C3B42',
  '#79192F',
  '#25D71B',
  '#75D227',
  '#8EEC62',
  '#95AB73',
  '#32FAC3',
  '#088831',
  '#33FE8D',
  '#A326DC',
  '#C0B0DC',
  '#7034D0',
  '#88B9F8',
  '#767771',
  '#04C025',
  '#416BBA',
  '#A98367',
  '#695787',
  '#73E832',
  '#E857F9',
  '#9F9FA9',
  '#AD67E4',
  '#E7D802',
  '#82C9DB',
  '#7A9697',
  '#4DB862',
  '#DC08B9',
  '#C94E3D',
  '#3D49F6',
  '#773D4B',
  '#E8D824',
  '#16931E',
  '#7FAA90',
  '#D56F5B',
  '#0470EE',
  '#E575C2',
  '#467FAB',
  '#8870CE',
  '#273C8B',
  '#C98289',
  '#5F187C',
  '#7570A2',
  '#C29AC8',
  '#3C04DA',
  '#72E6AE',
  '#24FEA0',
  '#685D9E',
  '#2FB0CF',
  '#B469E7',
  '#942473',
  '#7CA44F',
  '#90A3CC',
  '#2E207A',
  '#83B013',
  '#448FA5',
  '#3B4646',
  '#20D979',
  '#2E5530',
  '#05AF51',
  '#D4E490',
  '#F92647',
  '#6197E7',
  '#81ABDA',
  '#2577BE',
  '#DF8588',
  '#4DFD50',
  '#38250D',
  '#5683A9',
  '#9B3FFE',
  '#063608',
  '#07D7A7',
  '#893447',
  '#C75D80',
  '#DB48EC',
  '#9D4FD1',
  '#423D1D',
  '#01E528',
  '#E73610',
  '#57A850',
  '#B6FFC2',
  '#BC550D',
  '#9D555F',
  '#341982',
  '#E368AF',
  '#87D8CA',
  '#EB0638',
  '#39E38A',
  '#588529',
  '#D98E35',
  '#44BAC3',
  '#9CCE66',
  '#D8B223',
  '#D07640',
  '#45D3BB',
  '#0FB269',
  '#60BDB7',
  '#674901',
  '#8B1DE2',
  '#7ECE10',
  '#70610F',
  '#0BEED5',
  '#153C98',
  '#F147DE',
  '#C952BA',
  '#CB1137',
  '#487041',
  '#360817',
  '#3E5AC5',
  '#2C94C3',
  '#B10532',
  '#3CB6AB',
  '#9AA9F3',
  '#DED5AB',
  '#FE5486',
  '#B03059',
  '#A45235',
  '#51A1B8',
  '#F5F95D',
  '#AE52C4',
  '#D26DFA',
  '#CF88CA',
  '#B54B3A',
  '#3C3CE0',
  '#E698D1',
  '#3C3E60',
  '#7B4183',
  '#D4FF78',
  '#204C70',
  '#FC75E8',
  '#6A4307',
  '#A29E74',
  '#3304DF',
  '#4CD957',
  '#B176E1',
  '#C6B178',
  '#58B27D',
  '#3C424C',
  '#0A9089',
  '#6612DA',
  '#707ED3',
  '#170C59',
  '#F9AAFB',
  '#6E588D',
  '#9F7B9F',
  '#25DB0F',
  '#B1122A',
  '#48796E',
  '#33237C',
  '#86F2F9',
  '#38E88A',
  '#DCF848',
  '#CD765C',
  '#5DEA24',
  '#55A4C5',
  '#8754A3',
  '#042E6F',
  '#1B90C9',
  '#7FE926',
  '#C77825',
  '#B39C61',
  '#EDB37D',
  '#65EA89',
  '#141359',
  '#0A9E6B',
  '#454E63',
  '#A074ED',
  '#CD978A',
  '#8651E0',
  '#8276FB',
  '#C30F59',
  '#F777AF',
  '#5B3AD7',
  '#6DB763',
  '#FD98B0',
  '#84C69C',
  '#87161D',
  '#68C83B',
  '#266640',
  '#31C629',
  '#12FAE0',
  '#68D6D9',
  '#E15590',
  '#E7615C',
  '#46E82E',
  '#629803',
  '#0C64BD',
  '#6758A4',
  '#302BF9',
  '#A85C34',
  '#E7BCC0',
  '#10D9E3',
  '#9A905C',
  '#1E85FA',
  '#E633B9',
  '#456448',
  '#CFB466',
  '#6DB32C',
  '#CBE692',
  '#828397',
  '#D6ACEB',
  '#4E0673',
  '#5C6490',
  '#F48E35',
  '#C9E9C0',
  '#04A3E2',
  '#ED9B26',
  '#112993',
  '#FE3115',
  '#4E0945',
  '#FC8EE1',
  '#BAEE58',
  '#C02E43',
  '#D7D9F9',
  '#A85453',
  '#07C686',
  '#F3E218',
  '#8FDEC4',
  '#1ADC36',
  '#E0AF4A',
  '#5ED9F7',
  '#C3FD10',
  '#DBC609',
  '#66A804',
  '#A81A5E',
  '#72E5A0',
  '#237B53',
  '#09A40D',
  '#01FB61',
  '#DC0E1A',
  '#1681E9',
  '#22198A',
  '#BDC771',
  '#23FD24',
  '#AF604F',
  '#CCE8B2',
  '#80ED1F',
  '#0C6548',
  '#B296C1',
  '#93D039',
  '#B095BC',
  '#1CD427',
  '#0CE6E9',
  '#A0285E',
  '#60BAB6',
  '#52879A',
  '#4EEC3E',
  '#1A85E5',
  '#65EDC0',
  '#FA15F5',
  '#AC995C',
  '#92D3A4',
  '#266031',
  '#CAAC7F',
  '#DE6C58',
  '#99F283',
  '#5BF4EB',
  '#EFE989',
  '#784C29',
  '#AAC7B0',
  '#40C5B7',
  '#F5FAEF',
  '#06B5C4',
  '#73AFF4',
  '#5CE983',
  '#D59E25',
  '#8B2CFB',
  '#AA6D8F',
  '#2B40AF',
  '#CDCC0F',
  '#787A31',
  '#2951A9',
  '#B346C4',
  '#2EA9CD',
  '#8FF47F',
  '#169201',
  '#DB7B1C',
  '#5A5C05',
  '#16E061',
  '#65DA78',
  '#DA2E1F',
  '#19465B',
  '#CBC838',
  '#D17F69',
  '#674E09',
  '#57EEC9',
  '#A54434',
  '#467480',
  '#A82C70',
  '#40F80E',
  '#CA2216',
  '#1BCA82',
  '#952B99',
  '#7A49A5',
  '#127ADC',
  '#D11E7F',
  '#B984B6',
  '#1DBD79',
  '#60E71B',
  '#C42A17',
  '#F61F4C',
  '#A77B49',
  '#17DE1F',
  '#0F23E9',
  '#4D10D4',
  '#8F0D50',
  '#EBB14E',
  '#EDE9B4',
  '#994CB2',
  '#2A34DA',
  '#69EE46',
  '#9C7228',
  '#AB7532',
  '#DE021D',
  '#D807C4',
  '#EDB052',
  '#667835',
  '#4CF102',
  '#CFEDF8',
  '#8B1FFD',
  '#818E75',
  '#098B1E',
  '#B8F4BE',
  '#6AED69',
  '#C86B4F',
  '#9EB8B6',
  '#493192',
  '#6C8527',
  '#2E0E1C',
  '#76ED56',
  '#B54FD3',
  '#05DF9E',
  '#EDB45C',
  '#D77F9A',
  '#0342B1',
  '#D9612D',
  '#D37620',
  '#59CDBB',
  '#23673E',
  '#CA12FC',
  '#EF3277',
  '#7B0EB8',
  '#815A58',
  '#D50086',
  '#AE1A95',
  '#551C8E',
  '#EE60A0',
  '#4B5BB9',
  '#729025',
  '#BE9D18',
  '#112F7E',
  '#DFBD74',
  '#4437F2',
  '#4B8592',
  '#7E2793',
  '#2BC188',
  '#9F336B',
  '#FED4EA',
  '#3FE91A',
  '#92546A',
  '#D71EF4',
  '#27B153',
  '#B64FFA',
  '#6924FF',
  '#43333D',
  '#51497F',
  '#29FC2C',
  '#0827E7',
  '#2BEDF8',
  '#F73240',
  '#1A4241',
  '#AEE551',
  '#4FF94D',
  '#C14B09',
  '#CE56D5',
  '#3B2592',
  '#7E8A30',
  '#1011A1',
  '#8762B5',
  '#077069',
  '#5EE2C4',
  '#4BD864',
  '#37E783',
  '#806792',
  '#1732AC',
  '#4C5249',
  '#F618B6',
  '#504B04',
  '#F7EA5C',
  '#17F06E',
  '#B55F17',
  '#64BEF4',
  '#E765FF',
  '#EFB659',
  '#E7EAA6',
  '#D327A6',
  '#49FBDA',
  '#B58A17',
  '#8CB3DE',
  '#A6FF04',
  '#6AD78A',
  '#CE08D5',
  '#A36FAE',
  '#7647F9',
  '#E4A846',
  '#163A33',
  '#2E31EA',
  '#68B636',
  '#58194B',
  '#90C29C',
  '#BF7DBF',
  '#FDC1EC',
  '#EB81B9',
  '#AC3A46',
  '#B00D35',
  '#BD72A7',
  '#69DB93',
  '#D3B34F',
  '#2ACA3D',
  '#48D8C1',
  '#BCF78F',
  '#AFFC8D',
  '#9F99D7',
  '#1D11D2',
  '#0642F9',
  '#D420C7',
  '#1A6B05',
  '#0D1A71',
  '#75F42C',
  '#0DC3C5',
  '#648E4D',
  '#077462',
  '#7680EB',
  '#DE07C6',
  '#9F0740',
  '#456078',
  '#407033',
  '#C5237A',
  '#AF9553',
  '#EC0C6B',
  '#6064BF',
  '#1945F5',
  '#70F4E5',
  '#A58B64',
  '#5EDF43',
  '#59D8DA',
  '#AF5AB1',
  '#CFD4DC',
  '#E2D7AB',
  '#644CB2',
  '#8594D4',
  '#3D0034',
  '#2BE137',
  '#A8F00E',
  '#FBEADC',
  '#F3D673',
  '#A5FC98',
  '#874AE2',
  '#6E480D',
  '#7A9280',
  '#B42D1E',
  '#3BC104',
  '#9D4427',
  '#CA3C81',
  '#D987C3',
  '#2D3CB4',
  '#4A05E4',
  '#6DC584',
  '#61DA2D',
  '#61C486',
  '#A03918',
  '#0AF5E2',
  '#9E4EEA',
  '#B73A05',
  '#7F9916',
  '#4F95AE',
  '#B048E3',
  '#F521AD',
  '#21E633',
  '#1AC466',
  '#A3107E',
  '#418F8D',
  '#9CA086',
  '#49B016',
  '#C0B90C',
  '#2C5A4A',
  '#C9AAA7',
  '#CC7CF7',
  '#D03D9D',
  '#5B2669',
  '#E416C7',
  '#215A40',
  '#B078F3',
  '#448B52',
  '#A99201',
  '#9A7785',
  '#4B80EE',
  '#A3E9E2',
  '#88FA88',
  '#06B90E',
  '#9BFB9F',
  '#184E97',
  '#E3AE75',
  '#696904',
  '#F7BFB4',
  '#23718D',
  '#511C6E',
  '#905963',
  '#CFB0EB',
  '#425C77',
  '#AC4D61',
  '#993558',
  '#ABC2C6',
  '#8D32BA',
  '#050A14',
  '#8C2493',
  '#67B12D',
  '#238A2F',
  '#D7454B',
  '#3E51CA',
  '#568BBA',
  '#0C5364',
  '#9487D4',
  '#0D1115',
  '#256B15',
  '#C09B6F',
  '#F74144',
  '#70BC24',
  '#4E7009',
  '#A87DF7',
  '#6E6408',
  '#B65988',
  '#151A48',
  '#4A2700',
  '#287687',
  '#01DE09',
  '#E82DCB',
  '#9BCFB4',
  '#F0B5C7',
  '#671AA7',
  '#4FBC33',
  '#AB76D1',
  '#47640F',
  '#B59D3A',
  '#649737',
  '#ABBB8A',
  '#37B910',
  '#502904',
  '#BFE787',
  '#68CC36',
  '#02843E',
  '#BFFA39',
  '#FAB247',
  '#C2A961',
  '#136292',
  '#002227',
  '#CBF5B7',
  '#1A2977',
  '#4729E2',
  '#2B3010',
  '#7B3407',
  '#DED15F',
  '#704103',
  '#75C753',
  '#0EB736',
  '#508EFD',
  '#C8CBB2',
  '#092F9F',
  '#898DAF',
  '#1834C2',
  '#0C87DA',
  '#1CEB2C',
  '#395315',
  '#28C035',
  '#78281F',
  '#28EA6A',
  '#396207',
  '#44197A',
  '#CBD8F8',
  '#EDDB7A',
  '#848A21',
  '#8C3D83',
  '#11706C',
  '#6A81B6',
  '#C9155F',
  '#E1EBFF',
  '#DAF65E',
  '#4242A3',
  '#44DF6E',
  '#FF1563',
  '#82335E',
  '#7CADB5',
  '#8B5A07',
  '#6BFE3D',
  '#E974EC',
  '#0C8F77',
  '#416669',
  '#B4C08B',
  '#C6768B',
  '#A87E5C',
  '#BE60C5',
  '#9DCB11',
  '#2071F7',
  '#5EA704',
  '#88943D',
  '#12B530',
  '#C6CE08',
  '#6A4233',
  '#EF865A',
  '#AE351B',
  '#972B47',
  '#564AB9',
  '#E793F8',
  '#B07D18',
  '#8B0E8B',
  '#38B316',
  '#84192F',
  '#C6F93A',
  '#8B6DA4',
  '#BFC14E',
  '#9505A2',
  '#2ECDA3',
  '#F617D9',
  '#CB1F42',
  '#2E71B8',
  '#4130A3',
  '#3D88F9',
  '#8C4AEC',
  '#7C4EA4',
  '#B4AA88',
  '#D9416C',
  '#75549F',
  '#642C42',
  '#AF9B2C',
  '#DE860B',
  '#FD1D0F',
  '#81BD6B',
  '#DEA777',
  '#F0AC6D',
  '#11717F',
  '#55ADB2',
  '#C7758D',
  '#3FDB6A',
  '#722057',
  '#B5EF20',
  '#F5CD93',
  '#52950C',
  '#5B2B32',
  '#1D6B55',
  '#A35E7D',
  '#FDDB30',
  '#C8485C',
  '#4EC9EA',
  '#BCB14A',
  '#D58BBB',
  '#881D6D',
  '#66F1A0',
  '#41D461',
  '#EE9C93',
  '#2BF691',
  '#9B752E',
  '#F58EF3',
  '#6FC8C1',
  '#EA393A',
  '#08EF9D',
  '#4BF0E7',
  '#7AD526',
  '#A652C7',
  '#BD9616',
  '#0CF54D',
  '#C07989',
  '#D5FD95',
  '#AEC19B',
  '#C7BAE5',
  '#E8317C',
  '#179ECF',
  '#F2B399',
  '#92F197',
  '#A13437',
  '#04C53F',
  '#6FD56C',
  '#E0028C',
  '#D89DDD',
  '#4625F7',
  '#C21C93',
  '#8E17D7',
  '#E52939',
  '#34357A',
  '#F3F725',
  '#36F70D',
  '#1A59D5',
  '#912F7F',
  '#2E0B5D',
  '#BDAA8D',
  '#A10DEC',
  '#C98C6A',
  '#78A1B9',
  '#8B1592',
  '#F84A7A',
  '#380913',
  '#890BB0',
  '#0A310B',
  '#B43761',
  '#2ED2C7',
  '#A34E10',
  '#DA9888',
  '#D5449C',
  '#832047',
  '#9CB9E6',
  '#14113F',
  '#9ACB52',
  '#52ACB1',
  '#3427CE',
  '#6DD687',
  '#2F39B2',
  '#E49990',
  '#048E73',
  '#CA40D8',
  '#6A7CA8',
  '#9F464A',
  '#98332A',
  '#97B064',
  '#BB19A1',
  '#BBCB6E',
  '#662726',
  '#020BE1',
  '#973991',
  '#9F8F16',
  '#45227A',
  '#3B3257',
  '#D2B548',
  '#477346',
  '#D690AB',
  '#8F0561',
  '#AA5E0A',
  '#50B273',
  '#810CB3',
  '#E40FC7',
  '#822F61',
  '#B9C90A',
  '#05C75A',
  '#5D9553',
  '#AF28A3',
  '#3E9395',
  '#B5B33B',
  '#5F6418',
  '#E73C94',
  '#AEF59B',
  '#F91579',
  '#E5A06D',
  '#4A9773',
  '#CE1474',
  '#C35B88',
  '#D2B45D',
  '#BB414E',
  '#2903A4',
  '#7379BF',
  '#55AE56',
  '#347A78',
  '#69A92F',
  '#283897',
  '#B75297',
  '#6C3A46',
  '#602CD1',
  '#9DD47D',
  '#021D6A',
  '#CFCB87',
  '#E8B1DA',
  '#73577F',
  '#C24F69',
  '#8850A9',
  '#427BCB',
  '#5BF513',
  '#A8DF9A',
  '#A83328',
  '#53F8E9',
  '#E033D2',
  '#C03356',
  '#2020C2',
  '#09F85D',
  '#7EF81B',
  '#AB64C2',
  '#D119F9',
  '#6D01FA',
  '#0D99DC',
  '#BF74DA',
  '#7330DE',
  '#EC289E',
  '#1DCA45',
  '#C994A9',
  '#43BC2E',
  '#C159EF',
  '#C988E8',
  '#57021C',
  '#E9E63C',
  '#B0C960',
  '#92B566',
  '#4144FB',
  '#B02BC6',
  '#EA1A9E',
  '#C0FE38',
  '#7A18C4',
  '#ED70E7',
  '#F2421B',
  '#C48323',
  '#E10BE4',
  '#B33FF6',
  '#665C23',
  '#0F4128',
  '#D2882F',
  '#988638',
  '#EB8C3E',
  '#F3E1C2',
  '#C8D7B6',
  '#748AFA',
  '#BE37F1',
  '#29050C',
  '#CD618C',
  '#417058',
  '#14CA8B',
  '#3B9B48',
  '#FA8090',
  '#2E5418',
  '#1B0AF4',
  '#182389',
  '#B5EBB7',
  '#74DB7C',
  '#EE0D89',
  '#B9C78A',
  '#F31881',
  '#DDE19D',
  '#6F7BC7',
  '#E04330',
  '#736B6A',
  '#639A37',
  '#8BDA17',
  '#6BB3D4',
  '#B970E3',
  '#670A28',
  '#D38F3F',
  '#FA4B0E',
  '#AC4518',
  '#991126',
  '#8E5A00',
  '#3494E3',
  '#82BCDE',
  '#40B857',
  '#CCC33A',
  '#93C36D',
  '#BD66FC',
  '#AE2305',
  '#8D3A6B',
  '#317ACB',
  '#080FC6',
  '#C725D5',
  '#106BFA',
  '#8E2FD3',
  '#697C6E',
  '#9B76F5',
  '#D9F1CC',
  '#863283',
  '#EA2993',
  '#D83F92',
  '#68706D',
  '#F6C788',
  '#9841B3',
  '#A83EDF',
  '#F2C50C',
  '#31E965',
  '#414917',
  '#4C2A33',
  '#897DA4',
  '#BE6D0A',
  '#4323C5',
  '#C585EE',
  '#A054A9',
  '#1596A8',
  '#841A15',
  '#6F1169',
  '#6F1CE5',
  '#401712',
  '#33C723',
  '#AC77D1',
  '#F984BE',
  '#98BDB0',
  '#3BE285',
  '#F2EE4F',
  '#3E3B7C',
  '#36EB57',
  '#5E4884',
  '#8AE778',
  '#9F13BF',
  '#27F902',
  '#11A137',
  '#9BA78E',
  '#0B98E7',
  '#DC7ED5',
  '#8B75FB',
  '#26E304',
  '#37BEAB',
  '#20BB4A',
  '#EC3ACA',
  '#CC0F6B',
  '#EF2419',
  '#6D73D5',
  '#9C35E2',
  '#1CDA5F',
  '#D4802D',
  '#3E77E8',
  '#5BD2C2',
  '#07BDEF',
  '#152D47',
  '#888156',
  '#FF84F7',
  '#F90219',
  '#91E635',
  '#3EB015',
  '#CD0DDC',
  '#55F7D6',
  '#4749DA',
  '#14344E',
  '#10A9CC',
  '#FD38BF',
  '#CD439C',
  '#66F756',
  '#D9ED93',
  '#44AA36',
  '#11A625',
  '#AD3564',
  '#70CB03',
  '#C9A840',
  '#0A3255',
  '#EA4F8A',
  '#A8930E',
  '#FB5F9E',
  '#7F5CBE',
  '#ADD6D3',
  '#6FFEE5',
  '#D1DEF5',
  '#A33C01',
  '#1A4C15',
  '#F509CB',
  '#0613BB',
  '#399DDD',
  '#CC4980',
  '#B3A840',
  '#099A00',
  '#A7BEDD',
  '#5CCBFC',
  '#A982AF',
  '#559993',
  '#7517FE',
  '#72AA67',
  '#BC5D03',
  '#DE1D9F',
  '#22E51C',
  '#CD2361',
  '#136481',
  '#CEF6B9',
  '#605E27',
  '#866763',
  '#A2AE3F',
  '#38490C',
  '#90306C',
  '#FD1F9A',
  '#36792F',
  '#5A3F6D',
  '#8654CA',
  '#C73B0D',
  '#C1EB7A',
  '#E6B92F',
  '#268D23',
  '#306DB7',
  '#D92B6B',
  '#B3E689',
  '#FD33F6',
  '#D0EEE4',
  '#6E031E',
  '#6A2B74',
  '#6ED61D',
  '#6F6230',
  '#ACB96A',
  '#540C79',
  '#38F6C1',
  '#D0BE9D',
  '#D5CB2D',
  '#112F45',
  '#209776',
  '#62EBF6',
  '#907074',
  '#7FC9FF',
  '#A4607A',
  '#E78366',
  '#4D8D96',
  '#0BEC52',
  '#46B382',
  '#612B6A',
  '#7C2C1E',
  '#9AA231',
  '#FD1481',
  '#238287',
  '#99E9B1',
  '#569364',
  '#EB296F',
  '#3319EE',
  '#732DDA',
  '#488EE2',
  '#8D78FD',
  '#860DC4',
  '#547BC9',
  '#59FFD9',
  '#770D80',
  '#3B525F',
  '#2434D4',
  '#C8277D',
  '#F25CA3',
  '#8676A1',
  '#E31380',
  '#646762',
  '#92C13E',
  '#2C4CB9',
  '#D65A90',
  '#411717',
  '#5EBCE0',
  '#F1751C',
  '#86DA6B',
  '#009AD4',
  '#BC5A9B',
  '#E97480',
  '#68EFE6',
  '#ED7E0D',
  '#0104C4',
  '#909061',
  '#84EAD7',
  '#797532',
  '#8C8F45',
  '#D99DE6',
  '#1E9622',
  '#93B44B',
  '#C6DC1B',
  '#C8A6F6',
  '#52A0ED',
  '#54BF30',
  '#8A261C',
  '#2FF258',
  '#F180E3',
  '#E5DE0B',
  '#6DA9A7',
  '#AADAB0',
  '#B1085F',
  '#9CFB33',
  '#03E040',
  '#F624A9',
  '#C30AB5',
  '#A7D30A',
  '#641A93',
  '#460762',
  '#C503FB',
  '#466456',
  '#46BDF9',
  '#F85120',
  '#0B89A8',
  '#702904',
  '#6EF0C3',
  '#4C3515',
  '#E3C91C',
  '#8EB6C4',
  '#784AA0',
  '#99AA8A',
  '#9E6061',
  '#32F7C9',
  '#BD1DF5',
  '#36CA01',
  '#7A7D1D',
  '#4A9CAC',
  '#525DA6',
  '#DCC6EA',
  '#677214',
  '#113490',
  '#6604A4',
  '#AD9422',
  '#0B1707',
  '#7A83A9',
  '#C0BAFC',
  '#5FC776',
  '#048B4D',
  '#76F47E',
  '#BC2038',
  '#E82B6D',
  '#7E6AB5',
  '#4DF616',
  '#350714',
  '#ED7D81',
  '#38B7FA',
  '#FF4780',
  '#86477C',
  '#5ABA0E',
  '#EEE287',
  '#5AC80D',
  '#860947',
  '#1A1982',
  '#E5326B',
  '#7BCBCE',
  '#B1DF2A',
  '#6D8A64',
  '#CB5820',
  '#DC8756',
  '#312A11',
  '#1CC615',
  '#BA7A48',
  '#11B079',
  '#507091',
  '#49B1BA',
  '#0B4E73',
  '#7E7DD3',
  '#5B0A0C',
  '#10F05F',
  '#8AFBCD',
  '#CCBA48',
  '#A4AF50',
  '#4C7572',
  '#A16905',
  '#459439',
  '#341288',
  '#4492B8',
  '#0D6B75',
  '#48B817',
  '#B9E5DC',
  '#E4FC45',
  '#8E03F1',
  '#2D23A0',
  '#78A27A',
  '#33E688',
  '#86053E',
  '#FFE2E3',
  '#E40C9C',
  '#0087CC',
  '#6F1FFD',
  '#887F2C',
  '#242323',
  '#6231B3',
  '#ADE300',
  '#D0EFE7',
  '#C33CE4',
  '#ECCB06',
  '#93F508',
  '#57C76C',
  '#D2FD68',
  '#D39B4C',
  '#F0D0E6',
  '#D8BDE4',
  '#BB7418',
  '#22DC3E',
  '#9177E8',
  '#FCA96D',
  '#7ED7A4',
  '#E07149',
  '#189431',
  '#CDB508',
  '#E65C28',
  '#CB0759',
  '#78889F',
  '#AF327B',
  '#156398',
  '#CB614A',
  '#618864',
  '#C5657C',
  '#6B2148',
  '#676334',
  '#AF6763',
  '#B1B461',
  '#E8C7FD',
  '#B77407',
  '#12E540',
  '#2DE361',
  '#9A1339',
  '#070A0F',
  '#24F89F',
  '#1F6988',
  '#15B555',
  '#56B7B1',
  '#559714',
  '#1D4CA1',
  '#F934C9',
  '#DC1A57',
  '#0C42D2',
  '#2D104D',
  '#B3AE44',
  '#9B3A04',
  '#954D05',
  '#1AF644',
  '#E1E337',
  '#6ED80B',
  '#F11FAF',
  '#656100',
  '#78266B',
  '#FF2843',
  '#4E2E70',
  '#DF9E28',
  '#B6E96F',
  '#35090C',
  '#1C1C88',
  '#B1CFA6',
  '#4B4547',
  '#D98E28',
  '#DF76D4',
  '#FA6A30',
  '#C7824C',
  '#E19E1C',
  '#454F60',
  '#73917F',
  '#F9B481',
  '#48E112',
  '#C4727A',
  '#CA25C3',
  '#E39176',
  '#958E4D',
  '#7DC173',
  '#2F11DC',
  '#6091E5',
  '#72CA32',
  '#4DDC9C',
  '#411AB3',
  '#66FA73',
  '#930A95',
  '#27555F',
  '#FBCEF6',
  '#6B5A38',
  '#AC3A28',
  '#593708',
  '#73CB00',
  '#964D6E',
  '#2A6F8F',
  '#6DA315',
  '#8D14B6',
  '#A24D1E',
  '#707D89',
  '#A7E746',
  '#C00611',
  '#DCD532',
  '#4CBC83',
  '#48E644',
  '#D1C706',
  '#C6AD0F',
  '#095709',
  '#56347E',
  '#6FB12C',
  '#B0D9FD',
  '#D90345',
  '#5FC3B5',
  '#B1CF06',
  '#D87CBD',
  '#F01F38',
  '#744D26',
  '#BF2BC6',
  '#FFC434',
  '#B84C78',
  '#805DEC',
  '#C0F38E',
  '#D503AA',
  '#131F96',
  '#122178',
  '#3CE6C6',
  '#7B7313',
  '#16D760',
  '#EC4AC6',
  '#F513D4',
  '#08030C',
  '#13EDE2',
  '#451319',
  '#CBBF31',
  '#2119FE',
  '#13D3ED',
  '#8D597E',
  '#3338EB',
  '#5C442E',
  '#1CD072',
  '#0A0342',
  '#1B1F6C',
  '#19CC65',
  '#9C37AA',
  '#0B5480',
  '#C868A6',
  '#E1ACA1',
  '#9DC63E',
  '#F2EE77',
  '#4456A5',
  '#A01A53',
  '#75A98E',
  '#DC679B',
  '#B2EF12',
  '#5735E3',
  '#673234',
  '#95686A',
  '#8087C7',
  '#B106AF',
  '#8C2120',
  '#684A31',
  '#0519EF',
  '#C015B7',
  '#A59CDB',
  '#59F7EC',
  '#79A6B3',
  '#F912A6',
  '#2DFF9C',
  '#87CFC1',
  '#993167',
  '#209305',
  '#7E331D',
  '#2EA4EB',
  '#D4331B',
  '#F5E466',
  '#F6E587',
  '#5A966B',
  '#556F17',
  '#A17E5A',
  '#AD6143',
  '#26A907',
  '#8D30C3',
  '#F8AA95',
  '#75950F',
  '#181F95',
  '#B6B7DC',
  '#D9B14A',
  '#B624EF',
  '#EB900B',
  '#E69767',
  '#77D613',
  '#60F0FF',
  '#11AFE7',
  '#9BA695',
  '#23D7C4',
  '#5B1E00',
  '#9C6C02',
  '#5FBDBC',
  '#90D99A',
  '#090942',
  '#70A97D',
  '#4E75AF',
  '#EB6A32',
  '#26B23A',
  '#A5EC1F',
  '#CED97F',
  '#DFA7E3',
  '#5746EB',
  '#DD143A',
  '#C9B2F6',
  '#90EE3E',
  '#3C30D3',
  '#D913BE',
  '#528172',
  '#419C67',
  '#E4BBCC',
  '#C4D50B',
  '#D01C25',
  '#100D47',
  '#C0793F',
  '#4089A2',
  '#5E44AB',
  '#7C8506',
  '#700B1E',
  '#0754B9',
  '#1FF663',
  '#AD67BC',
  '#756C5C',
  '#473BB6',
  '#3896AB',
  '#EFCF30',
  '#230C2B',
  '#9C089B',
  '#47A7AC',
  '#AA1FD4',
  '#2C981D',
  '#BFC216',
  '#F0A6D7',
  '#801CCC',
  '#236879',
  '#67A2C7',
  '#16D9A1',
  '#8A1D6D',
  '#228299',
  '#AE0FD3',
  '#BC196F',
  '#2E7A55',
  '#B4A2BC',
  '#189BC7',
  '#330214',
  '#A4E207',
  '#D922CA',
  '#2FED5E',
  '#D10372',
  '#548362',
  '#0931DB',
  '#9966D6',
  '#78E599',
  '#272CBC',
  '#A649AC',
  '#3F3845',
  '#D440BC',
  '#FC34AC',
  '#0D17C1',
  '#44B15C',
  '#08BE79',
  '#121A32',
  '#36C0A6',
  '#589718',
  '#A18961',
  '#7DFE8B',
  '#7582FA',
  '#89FD25',
  '#772534',
  '#BE8435',
  '#06AAED',
  '#D516B7',
  '#1BABC6',
  '#67A2CB',
  '#727B79',
  '#4674E8',
  '#94B889',
  '#EC3010',
  '#E58586',
  '#A81E40',
  '#D2E404',
  '#358316',
  '#EF980E',
  '#110B0B',
  '#7D0DB5',
  '#48151D',
  '#74BB91',
  '#330E5B',
  '#8A5508',
  '#84484C',
  '#4B11CC',
  '#057224',
  '#21EBB7',
  '#C1BD88',
  '#4A0CCF',
  '#450074',
  '#28899D',
  '#275701',
  '#B50BE3',
  '#67DBC0',
  '#E77501',
  '#D9E5FD',
  '#9C8119',
  '#CF127F',
  '#9025C0',
  '#BB53C2',
  '#363BF9',
  '#7B656C',
  '#8A629D',
  '#69CB52',
  '#C8CB68',
  '#EF5566',
  '#BC2CF0',
  '#247174',
  '#B075A2',
  '#F29549',
  '#804A01',
  '#165821',
  '#520691',
  '#0208F2',
  '#F0F6A5',
  '#7FBC95',
  '#B07456',
  '#9A9F23',
  '#AD7473',
  '#3648A2',
  '#A4A72E',
  '#1BD584',
  '#FD5B28',
  '#23959E',
  '#931FF5',
  '#591860',
  '#5D154A',
  '#E8A601',
  '#3310B5',
  '#DA6834',
  '#EDA4CF',
  '#E7505A',
  '#EEFBFA',
  '#97BDC0',
  '#D445E9',
  '#612F8D',
  '#61A697',
  '#70AEE9',
  '#581DEF',
  '#7B22B1',
  '#7B61F0',
  '#DA4A4E',
  '#CE65A3',
  '#C17A56',
  '#2E8501',
  '#842593',
  '#077A75',
  '#07DA24',
  '#79AFE7',
  '#E6B9D6',
  '#491A55',
  '#5E5ED9',
  '#69BF7D',
  '#03FCF6',
  '#443CB9',
  '#53C093',
  '#7F1BF0',
  '#9285EA',
  '#57497A',
  '#32F550',
  '#F42C2A',
  '#BC9098',
  '#5E2A6E',
  '#B5DE09',
  '#1F19C9',
  '#77E5F8',
  '#DC7C47',
  '#966D2E',
  '#6039D3',
  '#0D3ACD',
  '#6A64E3',
  '#8F353B',
  '#76BD78',
  '#539E0C',
  '#02F33B',
  '#766F53',
  '#F57EC1',
  '#F1B72C',
  '#65F828',
  '#60C923',
  '#60EF51',
  '#352FA2',
  '#4A0C18',
  '#AF63A2',
  '#286C99',
  '#F486EB',
  '#40B32A',
  '#0A3F26',
  '#4BC955',
  '#CD891B',
  '#B4465C',
  '#3F5C4B',
  '#B23790',
  '#ABE0C3',
  '#0846D8',
  '#2C9879',
  '#8866F6',
  '#14ABAE',
  '#64DAA2',
  '#17DA64',
  '#633706',
  '#FA6861',
  '#9ED973',
  '#528F30',
  '#B72A5F',
  '#3BF730',
  '#0B67E5',
  '#5AB6FB',
  '#795B96',
  '#9397A7',
  '#8A43B8',
  '#42BC9D',
  '#17322F',
  '#A562F3',
  '#D45C38',
  '#7A4689',
  '#E54671',
  '#8DEB31',
  '#9DD79A',
  '#18FA14',
  '#287703',
  '#CF264E',
  '#138C04',
  '#BE8D7E',
  '#E9B8E8',
  '#4A1AF0',
  '#6A51B7',
  '#19E161',
  '#C48201',
  '#C57C52',
  '#81BC1E',
  '#65B4DE',
  '#012939',
  '#5365BA',
  '#E62C87',
  '#A36E8C',
  '#5432F0',
  '#B4406A',
  '#606B6F',
  '#984F6F',
  '#94A9B4',
  '#964FDB',
  '#AADBA6',
  '#D2156F',
  '#C6B194',
  '#54E48C',
  '#558685',
  '#DC4B96',
  '#C0E59D',
  '#55DD34',
  '#70B56D',
  '#F0C9EF',
  '#FB1B02',
  '#C9FDD7',
  '#6AEAF5',
  '#7F574B',
  '#88A115',
  '#97D616',
  '#B7110D',
  '#B0CE96',
  '#340077',
  '#904199',
  '#F885A8',
  '#937135',
  '#3A57CF',
  '#DC9394',
  '#C64314',
  '#8089F7',
  '#BD99CE',
  '#3E0827',
  '#8AD51E',
  '#25E94D',
  '#F5351A',
  '#BB20F1',
  '#224238',
  '#35213A',
  '#1ECE34',
  '#03FC42',
  '#EE1C84',
  '#BA71F9',
  '#B05666',
  '#4A20CE',
  '#1A4505',
  '#956F84',
  '#0518FC',
  '#ED0FBA',
  '#9D46DE',
  '#46FECB',
  '#B42497',
  '#4E9B2F',
  '#DE5928',
  '#00C22D',
  '#D31A99',
  '#A46820',
  '#B5A58F',
  '#6EC31F',
  '#554EB5',
  '#13E27D',
  '#7E92AB',
  '#034196',
  '#044166',
  '#DBEFDF',
  '#1F67D7',
  '#A3BCC4',
  '#E5A714',
  '#427586',
  '#49A1B6',
  '#6C80BC',
  '#4DD4B8',
  '#95492C',
  '#FE2B59',
  '#3D815E',
  '#0FEABE',
  '#FACD0F',
  '#315671',
  '#DEEEB3',
  '#C75EC2',
  '#0C63F5',
  '#84D347',
  '#225A73',
  '#EC8444',
  '#524A98',
  '#584FEE',
  '#12BD9A',
  '#52ADCB',
  '#04CC31',
  '#E6B7A4',
  '#0F1093',
  '#6CBBD4',
  '#B9CA78',
  '#A9E785',
  '#149BE3',
  '#EB244B',
  '#6B8A58',
  '#B042FF',
  '#D11F10',
  '#8FD575',
  '#CDD004',
  '#84C678',
  '#8B20D4',
  '#E9804F',
  '#D8D1E6',
  '#BE76BB',
  '#A0575E',
  '#E72770',
  '#BCA33F',
  '#C0E796',
  '#C863CB',
  '#A6B895',
  '#ABCD6E',
  '#3958D3',
  '#9B1248',
  '#A80C0C',
  '#0B7FF3',
  '#82F3EA',
  '#762FC0',
  '#CA9EBA',
  '#B87CAC',
  '#AC32EB',
  '#69D39A',
  '#E8342E',
  '#5B8192',
  '#90CF3A',
  '#34BF7B',
  '#380E3C',
  '#1436B1',
  '#C95C00',
  '#C7DA7A',
  '#04A265',
  '#6693A8',
  '#69C958',
  '#10B84B',
  '#F7A326',
  '#F4AC72',
  '#F944A8',
  '#C8F292',
  '#2B1EA8',
  '#2C9232',
  '#B25753',
  '#1FD7AB',
  '#56036F',
  '#06B379',
  '#D5153F',
  '#A1034F',
  '#E7C9CC',
  '#C2AA01',
  '#0A4384',
  '#2F014A',
  '#24C1C2',
  '#63CF49',
  '#DCCC2E',
  '#93D3F7',
  '#CF706F',
  '#7DE1BC',
  '#547230',
  '#0C6744',
  '#F64C4F',
  '#1AE5B8',
  '#38F3DB',
  '#EB1810',
  '#D5FEE9',
  '#A24162',
  '#CAB28E',
  '#F7C058',
  '#A3EE8D',
  '#128491',
  '#4A7EBF',
  '#AB6097',
  '#472441',
  '#27D2DD',
  '#7ECEB3',
  '#C9CC23',
  '#5CB1E6',
  '#CD8D4C',
  '#0623D7',
  '#A8C11B',
  '#A18DDB',
  '#34B74E',
  '#792912',
  '#CDF018',
  '#5CEFBE',
  '#4F97B0',
  '#7BBE03',
  '#40BBA6',
  '#7C60A4',
  '#D8BBBE',
  '#68D24E',
  '#161DA3',
  '#A9DE45',
  '#02C989',
  '#ACFD01',
  '#2BDFCF',
  '#9DE515',
  '#9CCE5B',
  '#843330',
  '#96EBD0',
  '#44987B',
  '#F7B5D4',
  '#8AA08E',
  '#CFECE2',
  '#A856DA',
  '#A0B861',
  '#3DA4BC',
  '#A6E3E7',
  '#4BFEFA',
  '#D338A3',
  '#16302E',
  '#B3E3D6',
  '#426393',
  '#9C9458',
  '#27DF4B',
  '#193712',
  '#F8D265',
  '#944C0B',
  '#5BBD0F',
  '#66DF61',
  '#B2B2BF',
  '#B4AF4E',
  '#E18347',
  '#60E185',
  '#13C0D3',
  '#1DD528',
  '#E3F715',
  '#906AD5',
  '#41AFAD',
  '#C152A3',
  '#A1C165',
  '#F90D7E',
  '#08BC5D',
  '#D1FDF9',
  '#E15DA3',
  '#42D88D',
  '#70FB87',
  '#1DD4BA',
  '#DE55AC',
  '#219CFC',
  '#3AF9AA',
  '#E87C0B',
  '#7B426E',
  '#0E5A12',
  '#7C19F6',
  '#04101C',
  '#AD5E3F',
  '#4D0CD9',
  '#CA08C4',
  '#15BD08',
  '#74F98A',
  '#E36383',
  '#E3E4BB',
  '#693408',
  '#C6CB31',
  '#7B8F6D',
  '#AE7B92',
  '#9B1073',
  '#62F69A',
  '#E314F6',
  '#E62223',
  '#CE27A2',
  '#7FECAB',
  '#3521A7',
  '#82B54A',
  '#1C1B97',
  '#6F8A9D',
  '#2414AE',
  '#7EDCA8',
  '#F83A53',
  '#9DC2B2',
  '#289A9F',
  '#C631A0',
  '#7A5B13',
  '#B27A2B',
  '#46D460',
  '#11A1BB',
  '#C0648D',
  '#7CF4F2',
  '#49C78A',
  '#2DCE2B',
  '#59B1A0',
  '#3EBD7F',
  '#215A7F',
  '#A9BA88',
  '#A2EC8F',
  '#C620E1',
  '#D9B1E1',
  '#EF47CA',
  '#CFDF19',
  '#78A911',
  '#86C930',
  '#D9ED42',
  '#AA8239',
  '#6874D7',
  '#73EC96',
  '#03A5F5',
  '#D9A07F',
  '#261E18',
  '#A6B5EA',
  '#34595E',
  '#C791BC',
  '#AC8DD2',
  '#A8583F',
  '#B20512',
  '#A12CDD',
  '#D48441',
  '#DAE4DD',
  '#E78B35',
  '#1D51E3',
  '#620AF5',
  '#EA73DE',
  '#7D7922',
  '#433689',
  '#906328',
  '#7FE676',
  '#305970',
  '#FB0E93',
  '#7B5926',
  '#AB5FEF',
  '#A01688',
  '#D061EA',
  '#4AB4C1',
  '#3B4B62',
  '#0EC949',
  '#8F3929',
  '#00E741',
  '#B79B88',
  '#73C1E1',
  '#29FE58',
  '#782110',
  '#BC1540',
  '#1F0146',
  '#533406',
  '#F83A91',
  '#606528',
  '#CAD40A',
  '#A29179',
  '#C0301A',
  '#7AE643',
  '#E7E3A3',
  '#66C710',
  '#323248',
  '#BCB039',
  '#1EEF55',
  '#C5069C',
  '#E8D98A',
  '#6DBB65',
  '#2EE0A2',
  '#C504EC',
  '#0CB9F1',
  '#17F895',
  '#F3172E',
  '#450352',
  '#AD2DA4',
  '#6F5236',
  '#12867D',
  '#9A03A4',
  '#97170D',
  '#122F64',
  '#C67422',
  '#70D3CC',
  '#5BC6C0',
  '#BE11CB',
  '#ACC152',
  '#39B523',
  '#515D89',
  '#13B35D',
  '#8EB47A',
  '#FFC376',
  '#415EBC',
  '#B7036B',
  '#4ADC47',
  '#E869B2',
  '#BC23F5',
  '#8CB0DD',
  '#274B96',
  '#0F6678',
  '#55CAD5',
  '#C7CFB8',
  '#F71C37',
  '#471A31',
  '#7D5325',
  '#8883E6',
  '#1050F5',
  '#F663AD',
  '#B9659B',
  '#895E9E',
  '#C4CAFF',
  '#F2F92D',
  '#13F06C',
  '#67B719',
  '#0C0D60',
  '#D02A26',
  '#756ECE',
  '#F988C6',
  '#6FA66C',
  '#C37CA7',
  '#BA9C63',
  '#B75C95',
  '#0CFC9D',
  '#23A475',
  '#5D2FC8',
  '#99F0FD',
  '#5BC988',
  '#1EBBF0',
  '#93A393',
  '#E00B66',
  '#7F1E1B',
  '#0EFC77',
  '#81D016',
  '#C08CF9',
  '#6E20A0',
  '#5404B7',
  '#DD4E24',
  '#433947',
  '#24F180',
  '#677005',
  '#7DADD2',
  '#4A2AB0',
  '#E7F990',
  '#72FEFF',
  '#6D6C43',
  '#AD267C',
  '#C24085',
  '#424007',
  '#22310B',
  '#C981FB',
  '#F71FE5',
  '#9B4BA6',
  '#924707',
  '#9AA6C4',
  '#6A652A',
  '#0AB412',
  '#AA6945',
  '#214370',
  '#15D688',
  '#86BF5A',
  '#CFF4D7',
  '#217E46',
  '#E3D521',
  '#6AAFCD',
  '#AF5106',
  '#A9F159',
  '#1DD0BB',
  '#21973D',
  '#3EA099',
  '#81800A',
  '#C42E8F',
  '#83ADAD',
  '#0DCBE8',
  '#C109D4',
  '#1A28FF',
  '#C450D4',
  '#09662F',
  '#2D823F',
  '#379C37',
  '#999050',
  '#4E4DF5',
  '#4B4162',
  '#5E0294',
  '#494DC0',
  '#EF3F64',
  '#273075',
  '#30D8F9',
  '#8ED48F',
  '#F3594C',
  '#7056AA',
  '#4EE705',
  '#20B774',
  '#75248D',
  '#9981B8',
  '#F15B73',
  '#FF3087',
  '#CA44A2',
  '#4C0E0F',
  '#B7B009',
  '#018338',
  '#E45B16',
  '#3BBFEA',
  '#87A716',
  '#0D1FA4',
  '#5EBA5E',
  '#6A1118',
  '#EDD5A7',
  '#A60FB8',
  '#EF2968',
  '#1D5C04',
  '#F55241',
  '#395E06',
  '#F23E9E',
  '#7B42D0',
  '#89A24D',
  '#D0DBA1',
  '#909299',
  '#0C979C',
  '#825A9F',
  '#C5FD78',
  '#2EFE54',
  '#EAB3EF',
  '#17AB5F',
  '#64E01C',
  '#D42884',
  '#D45C29',
  '#709C85',
  '#13105C',
  '#D35A8D',
  '#92D3DB',
  '#3EF551',
  '#86DA06',
  '#72F02C',
  '#5E91DD',
  '#412626',
  '#C9BC55',
  '#09882F',
  '#B9322F',
  '#70D35D',
  '#97E3B6',
  '#5E1695',
  '#7B8A03',
  '#2991A0',
  '#52FCF4',
  '#4924A8',
  '#80C590',
  '#D68501',
  '#32F2CB',
  '#366BC5',
  '#CCF4CD',
  '#F5F53A',
  '#D84E53',
  '#C8D4B7',
  '#68FFF0',
  '#3B9449',
  '#47AB19',
  '#93485A',
  '#F6F06A',
  '#18E458',
  '#CF7487',
  '#FD8EF4',
  '#65E872',
  '#C5CF3C',
  '#3DF2AB',
  '#D3BD6C',
  '#1118C0',
  '#F91B7C',
  '#5B1965',
  '#4E7676',
  '#8D9C0D',
  '#6F9E0B',
  '#36F223',
  '#2F9FC6',
  '#261927',
  '#FF8B07',
  '#514988',
  '#AE7442',
  '#15F14E',
  '#44FCC4',
  '#767991',
  '#B5151A',
  '#99D7AB',
  '#EA0072',
  '#52334D',
  '#E52CC6',
  '#A3A9B4',
  '#FB024D',
  '#566EB5',
  '#F30922',
  '#2D1A48',
  '#93371E',
  '#AEBE39',
  '#F855B8',
  '#CBF47E',
  '#29AC28',
  '#5548B9',
  '#486C9D',
  '#EF7578',
  '#C44F75',
  '#CABB8E',
  '#566AFA',
  '#A0ED23',
  '#54508D',
  '#E80839',
  '#4A5022',
  '#38FE40',
  '#0E031C',
  '#92E3A1',
  '#E8D575',
  '#34C4C4',
  '#E2782A',
  '#C5301D',
  '#5157B3',
  '#668F2C',
  '#C66908',
  '#4ECC41',
  '#716CD4',
  '#E18245',
  '#BAE87C',
  '#07C197',
  '#E2DB02',
  '#FFC923',
  '#470072',
  '#75F7D3',
  '#468C7B',
  '#246AC1',
  '#528728',
  '#B4BAC7',
  '#7B8EC6',
  '#F5BEF6',
  '#98AA12',
  '#D7D2BF',
  '#373537',
  '#A0DA2A',
  '#3035D3',
  '#833B6D',
  '#3A0A80',
  '#552D69',
  '#E539F0',
  '#B4D51C',
  '#BC72B7',
  '#C85D24',
  '#3232CB',
  '#A1F39B',
  '#DC0ABF',
  '#20EA58',
  '#D6E189',
  '#C206AD',
  '#2FD93E',
  '#5F6D5A',
  '#8BCB43',
  '#55D887',
  '#F1A3E1',
  '#21A6B8',
  '#E0A253',
  '#5DFB5F',
  '#055EF5',
  '#5D6FCF',
  '#F58C64',
  '#765AD0',
  '#331698',
  '#7E7AD7',
  '#A445F0',
  '#7852E9',
  '#30FA71',
  '#D9CDB1',
  '#CEE985',
  '#4A9A7A',
  '#7B8BE0',
  '#AEFE22',
  '#EA2FBF',
  '#456DB2',
  '#5DC3C8',
  '#6C5C68',
  '#DD444A',
  '#C8D27B',
  '#F0B9F3',
  '#DC76C8',
  '#ED9265',
  '#61A6C3',
  '#095AA6',
  '#820985',
  '#92CF43',
  '#59B32D',
  '#C1EEA4',
  '#A31DF6',
  '#1096BD',
  '#920498',
  '#8D0AA4',
  '#62B917',
  '#54DDE0',
  '#BA75C8',
  '#412A5B',
  '#706D35',
  '#C631DD',
  '#BEE946',
  '#37FDAF',
  '#93BC0A',
  '#F9739F',
  '#BD4B0F',
  '#7CD63B',
  '#B56C50',
  '#EC6C8D',
  '#90455B',
  '#7E9107',
  '#A6DED6',
  '#399D16',
  '#515A95',
  '#FBCC92',
  '#80BFF5',
  '#40121F',
  '#D93BD8',
  '#AEDD74',
  '#23CC4A',
  '#C87F8F',
  '#000D1C',
  '#C83143',
  '#D478D7',
  '#21DA08',
  '#9512F7',
  '#32C2F5',
  '#556FCC',
  '#944BD3',
  '#019FBF',
  '#ACEF69',
  '#58C3BA',
  '#8CFD58',
  '#E4A337',
  '#1B30B4',
  '#DBB832',
  '#786074',
  '#ADDBC4',
  '#7A7470',
  '#49BF8C',
  '#986D84',
  '#E3810E',
  '#1762B0',
  '#A57CCC',
  '#B2A57C',
  '#CEACAC',
  '#39C959',
  '#6C1194',
  '#34455E',
  '#E4F74E',
  '#8C673D',
  '#700A02',
  '#CB3644',
  '#40B5FD',
  '#174139',
  '#D199E5',
  '#2DC281',
  '#3DAB27',
  '#9BB2F7',
  '#2739A9',
  '#F4BD1E',
  '#B1FA0A',
  '#94485E',
  '#A2313F',
  '#1FBBAE',
  '#64C51F',
  '#3B60BD',
  '#6D2EE7',
  '#703233',
  '#2FB11F',
  '#61CA33',
  '#468288',
  '#042774',
  '#559AAE',
  '#39F6D1',
  '#78F4FB',
  '#91BF1F',
  '#E23B30',
  '#957771',
  '#AC1417',
  '#547E33',
  '#81E711',
  '#37C74D',
  '#FFE939',
  '#B00AA7',
  '#24E3B1',
  '#49C1D0',
  '#1B3340',
  '#A9B46A',
  '#0BA88F',
  '#7B5A7F',
  '#9367B0',
  '#15B840',
  '#AB296C',
  '#78A441',
  '#AF363C',
  '#E00895',
  '#48638B',
  '#8A679B',
  '#70DAA9',
  '#2FEDA7',
  '#B97645',
  '#39BB2E',
  '#6EB1B4',
  '#75F317',
  '#8BB828',
  '#7E442D',
  '#547E5C',
  '#35B131',
  '#7A2FD0',
  '#9A7B53',
  '#84A452',
  '#25C7DF',
  '#D6D819',
  '#3E4A84',
  '#0767CA',
  '#EFBD06',
  '#1D9B78',
  '#CE4F8B',
  '#C13224',
  '#886FC6',
  '#26DFFB',
  '#3205E5',
  '#489304',
  '#014364',
  '#528A5D',
  '#681CFE',
  '#6885B8',
  '#DFB0BD',
  '#124880',
  '#8D6697',
  '#C437F9',
  '#023904',
  '#6F11CC',
  '#36EADD',
  '#588C6A',
  '#952230',
  '#60E3B7',
  '#11EF29',
  '#149DF1',
  '#40511E',
  '#1430DD',
  '#F6CB19',
  '#973162',
  '#82C220',
  '#D40FB1',
  '#7FAB21',
  '#90AF38',
  '#D8709B',
  '#430096',
  '#E7EAA4',
  '#22A616',
  '#0A5677',
  '#2BDE90',
  '#7112F4',
  '#7D883B',
  '#67B182',
  '#C7BA32',
  '#8C5FFA',
  '#A0E800',
  '#585240',
  '#3CDD3D',
  '#31A6FA',
  '#752345',
  '#FEC092',
  '#C53CD4',
  '#BB7382',
  '#E23AFA',
  '#37DAA7',
  '#041C9B',
  '#A70CE8',
  '#F24E0F',
  '#37B3B4',
  '#2BFB4B',
  '#100400',
  '#6F9B87',
  '#2DE72C',
  '#044136',
  '#9EF2F1',
  '#8DCB12',
  '#FB89C8',
  '#86442F',
  '#86A011',
  '#92DC1E',
  '#2A197D',
  '#BE4D28',
  '#6350E6',
  '#204323',
  '#E115AE',
  '#B6169B',
  '#3E3530',
  '#1E86E0',
  '#D1BDAB',
  '#E6FF27',
  '#997937',
  '#7A8F31',
  '#38E72D',
  '#E5B4DE',
  '#3FA3D7',
  '#F98BC0',
  '#2BBD72',
  '#9DF5FE',
  '#04B676',
  '#894FE3',
  '#151EB7',
  '#251017',
  '#ADEE8A',
  '#025501',
  '#9432F7',
  '#4328E3',
  '#12DE90',
  '#534C13',
  '#9D5AFB',
  '#FE8C48',
  '#158C23',
  '#ADF3FC',
  '#106975',
  '#FB5F9D',
  '#36F3C9',
  '#1BFA36',
  '#C9FA52',
  '#199086',
  '#347D6C',
  '#DA5065',
  '#585E58',
  '#52D15B',
  '#E884EE',
  '#04186B',
  '#9DCAB0',
  '#D14803',
  '#2E0741',
  '#F43B32',
  '#0431C0',
  '#05C002',
  '#053070',
  '#6B76C2',
  '#AA26EF',
  '#F8B2CC',
  '#B2AD9F',
  '#69AF55',
  '#5B8DF4',
  '#CD2697',
  '#4479DA',
  '#36ECC1',
  '#842976',
  '#4A8C29',
  '#BDE865',
  '#99C919',
  '#506E4E',
  '#134484',
  '#C3A25F',
  '#C08405',
  '#2FD760',
  '#ED0352',
  '#D9540F',
  '#C23CDE',
  '#B5C9C3',
  '#986470',
  '#8DC473',
  '#B854D9',
  '#093E82',
  '#58ACE5',
  '#97C364',
  '#18FA4F',
  '#E9CF03',
  '#471CAF',
  '#B72C46',
  '#7A2E75',
  '#C40B8F',
  '#22462F',
  '#0AC87C',
  '#3AE9E2',
  '#4D63E6',
  '#9F4F13',
  '#BA6FCB',
  '#985161',
  '#CFBB41',
  '#F7688A',
  '#354950',
  '#F7EB2B',
  '#ABE648',
  '#B7561F',
  '#5C00D6',
  '#CACA03',
  '#E530C2',
  '#FE43F8',
  '#E27312',
  '#C7A6E8',
  '#F884FD',
  '#4D5710',
  '#D45F3A',
  '#D41EEA',
  '#C8B570',
  '#2F228F',
  '#51114C',
  '#7335F4',
  '#3FBB18',
  '#728F19',
  '#220492',
  '#38D5D7',
  '#660B2F',
  '#8B0993',
  '#46CA1B',
  '#C91146',
  '#415F22',
  '#2F344D',
  '#1ECC4F',
  '#347A49',
  '#F9F206',
  '#C2FBEF',
  '#92086E',
  '#66F3EC',
  '#1F4394',
  '#9E0D73',
  '#B3BAF9',
  '#7CF075',
  '#464ABD',
  '#19C218',
  '#054EA0',
  '#A2419F',
  '#A20827',
  '#C35CF0',
  '#EB66ED',
  '#E45A1E',
  '#A648EC',
  '#E7574E',
  '#C64EA7',
  '#3B68AB',
  '#EC4161',
  '#E6F44F',
  '#8EC64E',
  '#40E19B',
  '#EAF9C7',
  '#B2DF51',
  '#882FA1',
  '#A34BE0',
  '#A065FE',
  '#B2B8E7',
  '#40F8E7',
  '#D0BF92',
  '#24583A',
  '#7BC817',
  '#5789AE',
  '#81F784',
  '#7CFBFE',
  '#6CD789',
  '#681019',
  '#7B2B6B',
  '#AABCDA',
  '#DDCE6D',
  '#F7DFE7',
  '#B527E4',
  '#2FF5AD',
  '#92FAD8',
  '#D8774D',
  '#A2FE1C',
  '#6FDF1C',
  '#E179E7',
  '#F5FA95',
  '#0C6F06',
  '#3B46DA',
  '#9A1859',
  '#A7061E',
  '#05D1CF',
  '#FF6113',
  '#4A67C7',
  '#444EF6',
  '#ADF2DF',
  '#E6E0C5',
  '#38D0B3',
  '#668617',
  '#E36E9A',
  '#1A7BA3',
  '#BCF327',
  '#F6E75F',
  '#5CC34D',
  '#3BEFD5',
  '#0139C9',
  '#85115C',
  '#28124D',
  '#97AD1A',
  '#263BCD',
  '#3A5CE0',
  '#389A17',
  '#AE680D',
  '#4A764C',
  '#87673A',
  '#9BFB85',
  '#C45E85',
  '#600C07',
  '#F3DB08',
  '#2F2C37',
  '#537596',
  '#C64D6F',
  '#FF2EC9',
  '#068623',
  '#513868',
  '#EEACF4',
  '#5A73B6',
  '#5CFA90',
  '#9FC902',
  '#BE38E2',
  '#C3BEA5',
  '#743574',
  '#E602DD',
  '#2E71B9',
  '#2A90E8',
  '#620E07',
  '#AC8BBB',
  '#B8FE35',
  '#4431D2',
  '#349C05',
  '#8185D9',
  '#58EE0F',
  '#347457',
  '#B0D0EB',
  '#C9BCFF',
  '#6A0B51',
  '#584C93',
  '#95D8BB',
  '#9F7C14',
  '#60DC7F',
  '#E885C0',
  '#1CF91F',
  '#40F889',
  '#6602C5',
  '#7E8EF4',
  '#04AE9B',
  '#DC04F7',
  '#A1F1B7',
  '#5D9BC9',
  '#A8936E',
  '#AE5A5F',
  '#92A066',
  '#5439C1',
  '#D68E03',
  '#A5398D',
  '#BFDF9A',
  '#0E2922',
  '#988E49',
  '#7C698B',
  '#4A5F08',
  '#5E1BCB',
  '#82B495',
  '#3584A8',
  '#CEDBD5',
  '#6A89C0',
  '#E6E006',
  '#0FC049',
  '#78BBF0',
  '#7DF59C',
  '#93D473',
  '#74B36A',
  '#58001B',
  '#C51299',
  '#E1FA52',
  '#B25509',
  '#A5445F',
  '#27D3F6',
  '#37FAC9',
  '#6B03F5',
  '#6196D0',
  '#610977',
  '#078A2C',
  '#99AE78',
  '#9F67FC',
  '#9B9E5B',
  '#26B88D',
  '#C96C9C',
  '#0DAFC2',
  '#69641E',
  '#3356D0',
  '#D1049D',
  '#A3ABBC',
  '#4E992E',
  '#10098E',
  '#071E50',
  '#34CE19',
  '#C722DD',
  '#C560DA',
  '#2EB169',
  '#E48F23',
  '#974A1A',
  '#3B59D0',
  '#C81011',
  '#B14460',
  '#8820A9',
  '#99B9DD',
  '#2E24C5',
  '#E10DA1',
  '#C496C4',
  '#19D662',
  '#22954B',
  '#7A39D5',
  '#A7281E',
  '#DA8A02',
  '#F93DA2',
  '#6C55CA',
  '#5D9D3D',
  '#0A0EAF',
  '#F87A0D',
  '#9C532F',
  '#E4E817',
  '#EA3D68',
  '#501BA1',
  '#2FDBD5',
  '#55DCCC',
  '#EC1141',
  '#D854B5',
  '#AC42C2',
  '#B38820',
  '#7D5AB2',
  '#3C3BFD',
  '#D4B892',
  '#6998D0',
  '#BEF953',
  '#1B0B3F',
  '#A2B02E',
  '#9425AB',
  '#2A8C57',
  '#D6FC0C',
  '#000B46',
  '#AD4826',
  '#8B71AE',
  '#229460',
  '#62AF20',
  '#F46D65',
  '#0649CE',
  '#82E5D6',
  '#69D398',
  '#E3F37B',
  '#A84F93',
  '#F460ED',
  '#0EB30A',
  '#D2F2BF',
  '#C9B554',
  '#7316A5',
  '#82C99A',
  '#B344A9',
  '#508092',
  '#E577C6',
  '#69B81F',
  '#A966E6',
  '#255382',
  '#145A3A',
  '#B791E4',
  '#A89D02',
  '#B931A4',
  '#01D1EB',
  '#989B0C',
  '#3F65D1',
  '#F4E3ED',
  '#13B5D0',
  '#823E61',
  '#A9D3CE',
  '#5CA077',
  '#593F0E',
  '#EAE013',
  '#A577BD',
  '#E7D219',
  '#49F514',
  '#432527',
  '#CF4B2F',
  '#DE9223',
  '#22480A',
  '#BDF052',
  '#CB649C',
  '#48E5C9',
  '#4B5B06',
  '#0715C4',
  '#AE0E7A',
  '#568796',
  '#D9203D',
  '#8F6800',
  '#9A8ACB',
  '#03C2D0',
  '#181F74',
  '#8213CD',
  '#165E21',
  '#5D3A1B',
  '#EC840D',
  '#302D82',
  '#8601E5',
  '#5C921D',
  '#0518DD',
  '#FCC429',
  '#ACC6A5',
  '#7E507F',
  '#8746FD',
  '#486106',
  '#E63551',
  '#EB1B68',
  '#80205B',
  '#1A1094',
  '#7FA997',
  '#5A4113',
  '#AF3A07',
  '#96BC91',
  '#92F2B1',
  '#73FEF6',
  '#82D3DE',
  '#D6AA50',
  '#BCCAF2',
  '#EF8CB9',
  '#190D5A',
  '#C0F960',
  '#B5769A',
  '#609D4F',
  '#B055CE',
  '#3C9846',
  '#70FFA7',
  '#1EB9FA',
  '#08CAE2',
  '#EAC862',
  '#1B9F58',
  '#745419',
  '#EEF07E',
  '#3D4FEF',
  '#4AC915',
  '#12BE4F',
  '#221D04',
  '#8AC59A',
  '#224EB8',
  '#DEFECF',
  '#A129B1',
  '#6EB9C3',
  '#5BBAC1',
  '#FD77E3',
  '#0F915A',
  '#3C810B',
  '#ADA58C',
  '#F1F1A3',
  '#1F4581',
  '#2C6E60',
  '#454B08',
  '#BA2EDC',
  '#2642B9',
  '#6B6D61',
  '#B69CFF',
  '#A8F901',
  '#AF9CC5',
  '#08822D',
  '#2888CE',
  '#2382C0',
  '#8DD4D6',
  '#03485E',
  '#598472',
  '#2346C5',
  '#8CE68A',
  '#F36426',
  '#7363A0',
  '#DD0433',
  '#0C47D6',
  '#6B2146',
  '#43F459',
  '#0AD085',
  '#84DD51',
  '#392381',
  '#20CAA8',
  '#332ED0',
  '#FDB271',
  '#C0F367',
  '#19D2F7',
  '#D146D2',
  '#D684FE',
  '#6D1320',
  '#DC2365',
  '#ADA0AE',
  '#7F61F8',
  '#4F73F4',
  '#EC0499',
  '#F16C36',
  '#8FE334',
  '#472FDA',
  '#1FC833',
  '#1384A2',
  '#E66344',
  '#C1DD64',
  '#F78EA3',
  '#FF2A83',
  '#1D7236',
  '#8E054A',
  '#1F9570',
  '#0B527B',
  '#710B6B',
  '#E325AB',
  '#2FB6C4',
  '#28CDA5',
  '#2897A6',
  '#6BC60F',
  '#C24F19',
  '#D33D44',
  '#FE370B',
  '#9153F5',
  '#4E6231',
  '#B352A7',
  '#195B28',
  '#878D8C',
  '#27AB8A',
  '#2C54CC',
  '#EC33C9',
  '#EB652A',
  '#A9EC49',
  '#C3005A',
  '#93EDD2',
  '#0787F6',
  '#60482B',
  '#E3FFDF',
  '#7349C7',
  '#C2BD9C',
  '#683734',
  '#82A954',
  '#2FC9D5',
  '#27D79F',
  '#326FFB',
  '#A7B2FB',
  '#074623',
  '#0C169D',
  '#BF76A9',
  '#803DB6',
  '#67377A',
  '#5FC064',
  '#2FCF11',
  '#52D6A3',
  '#46D324',
  '#364116',
  '#084458',
  '#CCE459',
  '#E3FE89',
  '#E49EAC',
  '#D57DF2',
  '#E1DA2F',
  '#C85AD5',
  '#3F78DD',
  '#C58064',
  '#AE1668',
  '#AFA2C4',
  '#F20A37',
  '#6CB2EF',
  '#264D1A',
  '#26C19F',
  '#EA78D4',
  '#5497E1',
  '#29DC5A',
  '#5A5899',
  '#4FDAA3',
  '#5DF5F6',
  '#5F0063',
  '#4AB69A',
  '#0C9E37',
  '#AF512A',
  '#D59FD7',
  '#881A13',
  '#3148C3',
  '#24031A',
  '#674FBC',
  '#BBA9D6',
  '#ABE400',
  '#5F389E',
  '#FBB6F2',
  '#648C99',
  '#99BBF9',
  '#0D138B',
  '#865A02',
  '#530278',
  '#0E5694',
  '#EC8387',
  '#DC9A4B',
  '#8FC3FB',
  '#CB0BEA',
  '#6101CE',
  '#D87C93',
  '#7BAC74',
  '#1BF97D',
  '#795452',
  '#13A74C',
  '#D79DC1',
  '#9C4001',
  '#ABE7D9',
  '#EDD0B6',
  '#8AD713',
  '#924EC8',
  '#005C3F',
  '#A0F7D1',
  '#D67FBB',
  '#705C74',
  '#231377',
  '#CDB46A',
  '#884ECF',
  '#EA1E88',
  '#B5A8F4',
  '#4EB80F',
  '#22C580',
  '#07AEF5',
  '#6017BC',
  '#A71A82',
  '#53095C',
  '#DCA34F',
  '#E54D57',
  '#180AE7',
  '#6468F7',
  '#30B490',
  '#851728',
  '#3DEABB',
  '#128203',
  '#325713',
  '#C92458',
  '#B2841A',
  '#341EC4',
  '#38670C',
  '#E8DD34',
  '#AD64D0',
  '#AA1824',
  '#C7F8D2',
  '#39218D',
  '#C2BB78',
  '#B74984',
  '#758AB3',
  '#C14CBF',
  '#1F795D',
  '#3B87BD',
  '#7AED94',
  '#524F10',
  '#499335',
  '#DA6CF9',
  '#F6C94A',
  '#03AE44',
  '#270A61',
  '#B44660',
  '#9AC06A',
  '#CB69B8',
  '#5EFF56',
  '#F3B4DE',
  '#CD51E5',
  '#FE925D',
  '#F0D1C8',
  '#B12227',
  '#7C1A0B',
  '#93B25A',
  '#58495E',
  '#7B8810',
  '#D7FFB4',
  '#580AEC',
  '#EFB4F7',
  '#1DA8E2',
  '#1AA4A6',
  '#781721',
  '#BFD2DF',
  '#1D4FC5',
  '#C1F7D7',
  '#3B043C',
  '#E66652',
  '#7A4FA2',
  '#9083CA',
  '#FEA9FF',
  '#8631E0',
  '#55468E',
  '#99E73D',
  '#780CC0',
  '#AD94F9',
  '#CEBD2B',
  '#2614FF',
  '#69B01D',
  '#AEFF6F',
  '#9F2D73',
  '#A5EB43',
  '#6BAE8E',
  '#B8780B',
  '#50D653',
  '#4794C9',
  '#A65630',
  '#6F9D01',
  '#F89C0D',
  '#553D61',
  '#9F5F8D',
  '#16F200',
  '#DB8624',
  '#918478',
  '#350B02',
  '#4737A7',
  '#174B95',
  '#B8F4DB',
  '#C72F05',
  '#C502F8',
  '#186124',
  '#12FECA',
  '#5BE279',
  '#9B7D99',
  '#A16914',
  '#632828',
  '#62246D',
  '#4A8C86',
  '#9103C9',
  '#0F3255',
  '#40FB39',
  '#E8C8F8',
  '#8153FD',
  '#85B0BB',
  '#8A6731',
  '#193A2A',
  '#D982E2',
  '#A85BCB',
  '#3D6BDC',
  '#3280C9',
  '#AB93EF',
  '#78C000',
  '#E0C6E8',
  '#5CCCE1',
  '#94AFC4',
  '#C0FF5C',
  '#9F49E7',
  '#F45C51',
  '#79D3F3',
  '#EB0E18',
  '#CE8D8D',
  '#7B2674',
  '#B87CDE',
  '#09335A',
  '#4AC7CC',
  '#2D129E',
  '#027E3D',
  '#D71DA7',
  '#B61CF0',
  '#9C946D',
  '#34198C',
  '#EF40D7',
  '#9AF16E',
  '#3EBB19',
  '#C22259',
  '#E83C63',
  '#4DADBD',
  '#30058C',
  '#C0DB2E',
  '#B8B66D',
  '#7F3F41',
  '#7340CA',
  '#41CD36',
  '#3E2A43',
  '#799C51',
  '#5F4DDE',
  '#31DD2D',
  '#953429',
  '#3ABBE7',
  '#D8EBC5',
  '#AFC17D',
  '#8A78D3',
  '#A2460E',
  '#95193B',
  '#D30B3A',
  '#F7BC61',
  '#BE6315',
  '#03DE68',
  '#C7D9EC',
  '#5AD573',
  '#F0437E',
  '#5F7DA4',
  '#C30330',
  '#F62B4C',
  '#0F0EC8',
  '#FDAF99',
  '#519E71',
  '#306D5C',
  '#1F1EA8',
  '#07D61D',
  '#EE023F',
  '#A9BB7B',
  '#7F8B00',
  '#D37D0C',
  '#BAB737',
  '#05569F',
  '#50DF9C',
  '#E9F01D',
  '#7AE4E7',
  '#47BFE5',
  '#111E5C',
  '#1E506C',
  '#0D76A9',
  '#1B8063',
  '#4B1FF9',
  '#57DE0D',
  '#E75E9D',
  '#867CDE',
  '#EBA76D',
  '#A060C7',
  '#28DFE4',
  '#673DD2',
  '#75A373',
  '#DEA1FB',
  '#9B0294',
  '#702353',
  '#16D718',
  '#FC5BE4',
  '#C81B5A',
  '#D6C6AD',
  '#98022C',
  '#735D7C',
  '#57DFE3',
  '#6A78D0',
  '#C5589F',
  '#D9E977',
  '#2E3A1E',
  '#91743F',
  '#CCB4C8',
  '#69C934',
  '#C00493',
  '#3FD8B2',
  '#A8F55E',
  '#241AAA',
  '#55695E',
  '#27FA65',
  '#76A203',
  '#8890AE',
  '#858E79',
  '#95C20D',
  '#B1B52F',
  '#E3C4F0',
  '#86D0D5',
  '#601682',
  '#4788D7',
  '#730486',
  '#57E603',
  '#D58CD6',
  '#B25800',
  '#7DB912',
  '#A33FF3',
  '#EFA0C4',
  '#2F0188',
  '#5472FC',
  '#38DA74',
  '#D596BB',
  '#939478',
  '#AB7D49',
  '#3EBB08',
  '#C1B83D',
  '#0C510D',
  '#EC3D43',
  '#698AC6',
  '#B635AC',
  '#08656D',
  '#1883FC',
  '#BF0D35',
  '#9ACFE1',
  '#FA60AF',
  '#6125CA',
  '#9D4BB4',
  '#660752',
  '#7C02CA',
  '#82782F',
  '#DB0DF9',
  '#88CEC7',
  '#4DF3D7',
  '#A812F5',
  '#BDA193',
  '#75A236',
  '#A827B5',
  '#702F9F',
  '#746FF2',
  '#D8152A',
  '#57B617',
  '#3C60F3',
  '#FDC5F7',
  '#44C111',
  '#61B1DE',
  '#9821AD',
  '#ECA5B3',
  '#0FA47C',
  '#DB308F',
  '#1F3E9A',
  '#9EB4F3',
  '#2009D5',
  '#396963',
  '#57AE8D',
  '#AD8110',
  '#911B83',
  '#A10518',
  '#6DBA45',
  '#0F44C9',
  '#E6F027',
  '#72E284',
  '#D06D00',
  '#1E7907',
  '#C59D0A',
  '#F9C6FE',
  '#E81E28',
  '#A22835',
  '#933575',
  '#28EAE3',
  '#8FF95A',
  '#62CDD8',
  '#001E29',
  '#70EADE',
  '#C7E135',
  '#D77EE8',
  '#67B7B5',
  '#5A938C',
  '#E2FA45',
  '#2901C6',
  '#15E794',
  '#ACB304',
  '#4B37A8',
  '#B75986',
  '#7924D5',
  '#26ECD2',
  '#5B59F8',
  '#6B012D',
  '#27A2A6',
  '#413C37',
  '#FF20DE',
  '#C6A76C',
  '#A23F69',
  '#7A4221',
  '#A5090F',
  '#B7A911',
  '#224CC3',
  '#70B312',
  '#D9A946',
  '#0206DE',
  '#7A60DA',
  '#F41B40',
  '#E075C8',
  '#FBDBA5',
  '#5F1447',
  '#B8E9B1',
  '#7506EA',
  '#43081E',
  '#2183AD',
  '#CECB53',
  '#604224',
  '#AA496A',
  '#75CA22',
  '#16777B',
  '#6F3964',
  '#303C57',
  '#E5A37B',
  '#C62714',
  '#10D102',
  '#55C363',
  '#9E60A0',
  '#DF8F2B',
  '#78565D',
  '#D181C7',
  '#ED221B',
  '#AFF9F4',
  '#83A947',
  '#26833A',
  '#4816CA',
  '#D7359A',
  '#3C970C',
  '#8EFA3A',
  '#0341F6',
  '#7C4070',
  '#B8A28B',
  '#F4B8E3',
  '#9E164B',
  '#5D07FB',
  '#2555F1',
  '#EE112A',
  '#EB238B',
  '#C5CD9A',
  '#687834',
  '#9894D3',
  '#714B8F',
  '#54BA7F',
  '#B336B7',
  '#1770AE',
  '#40C5FC',
  '#CFAE98',
  '#F42D5B',
  '#DCBC8D',
  '#72E9FD',
  '#11DABE',
  '#AF387B',
  '#6F5605',
  '#97162E',
  '#5B8297',
  '#F73E43',
  '#CEDF12',
  '#A0AB6B',
  '#30F09E',
  '#223E2C',
  '#7A7BC1',
  '#3724E8',
  '#A053F2',
  '#3883D7',
  '#2592D7',
  '#36352D',
  '#1479C7',
  '#881C3A',
  '#7C025E',
  '#837AA0',
  '#26DBF4',
  '#50C53F',
  '#CFC3B6',
  '#44E9BB',
  '#AAFD08',
  '#C8DA33',
  '#EBA749',
  '#F9422F',
  '#F10138',
  '#D74258',
  '#7F76AB',
  '#2FCE85',
  '#9157A9',
  '#F02C91',
  '#232B71',
  '#130FB2',
  '#0C22F0',
  '#D76F14',
  '#F9AB3F',
  '#20F046',
  '#8684C7',
  '#9D2063',
  '#6A77A7',
  '#0BBCFE',
  '#E3A0BA',
  '#DE4302',
  '#04ADCA',
  '#64684A',
  '#CB3450',
  '#E3A8C1',
  '#0305C2',
  '#96DAA3',
  '#D5609C',
  '#500FFF',
  '#14C307',
  '#6D417F',
  '#8C109C',
  '#74EAAB',
  '#8D52E0',
  '#668B0E',
  '#649D5E',
  '#EE755F',
  '#789E68',
  '#4AE405',
  '#4D2203',
  '#4F3E9D',
  '#0AD477',
  '#BA82BC',
  '#B1703E',
  '#72C652',
  '#D5F923',
  '#8BDF9D',
  '#585B53',
  '#AF178C',
  '#42C6C7',
  '#F13B8B',
  '#EC1BB7',
  '#C0CDED',
  '#480426',
  '#DED2F3',
  '#B2B075',
  '#F5DBA9',
  '#A9FCD4',
  '#50881C',
  '#D1D641',
  '#9ED798',
  '#0CD928',
  '#FCEEF6',
  '#D4A4F0',
  '#D2C227',
  '#79B06B',
  '#A38959',
  '#887522',
  '#FAB1A3',
  '#C6BE7A',
  '#6AE1BB',
  '#7D2044',
  '#5FE201',
  '#024C21',
  '#5820FE',
  '#2E8F3F',
  '#E27CB2',
  '#0FDE3D',
  '#702702',
  '#E320FD',
  '#820112',
  '#DB77B5',
  '#AB937F',
  '#9AA76F',
  '#8D3A26',
  '#C8647F',
  '#734491',
  '#E72618',
  '#352FC4',
  '#2A095A',
  '#154A6A',
  '#7A132D',
  '#FCCE22',
  '#763C82',
  '#EC23BD',
  '#8167DC',
  '#EFF22F',
  '#7F2162',
  '#017D04',
  '#FE0406',
  '#4115D7',
  '#47B682',
  '#E573BE',
  '#8E4885',
  '#BA24B7',
  '#9C9CBE',
  '#0EBCA6',
  '#E112FC',
  '#95194F',
  '#4FE0D8',
  '#659D5E',
  '#C2D9AF',
  '#E08640',
  '#5055E0',
  '#126543',
  '#D860EB',
  '#3B9142',
  '#CDD5EA',
  '#13992B',
  '#D59839',
  '#CE418D',
  '#07C120',
  '#8DA31D',
  '#46E20D',
  '#8E3FA6',
  '#AF7BE4',
  '#F40782',
  '#D7AB6C',
  '#62C03C',
  '#06667D',
  '#1DA41E',
  '#BAC1D6',
  '#85063E',
  '#2B6C5C',
  '#CC3F97',
  '#C9C400',
  '#2A3B5E',
  '#C1AADE',
  '#FC3390',
  '#2140BB',
  '#373AF4',
  '#8C1FC3',
  '#1376CD',
  '#9DF40C',
  '#2F10A1',
  '#64E883',
  '#5B3B6E',
  '#E913CA',
  '#3ABBCC',
  '#2839AF',
  '#9B25F6',
  '#7F1E28',
  '#6A846A',
  '#7EC8F9',
  '#58722B',
  '#8B6792',
  '#803487',
  '#E5D41F',
  '#ECA9E6',
  '#A075D7',
  '#C8A1E0',
  '#43B192',
  '#3111AF',
  '#21D11F',
  '#3CF5E6',
  '#E3B2DE',
  '#E5E7A8',
  '#82A5C3',
  '#E77FE9',
  '#4B7534',
  '#EFCAFD',
  '#4179EF',
  '#A3062C',
  '#1462E4',
  '#E3AA9B',
  '#B7BF59',
  '#AD54BD',
  '#EAAB7E',
  '#EBA4A5',
  '#A739A3',
  '#1F873E',
  '#38227E',
  '#C85CD3',
  '#7DCF82',
  '#580A50',
  '#C15355',
  '#191E3F',
  '#123935',
  '#2D948B',
  '#8FEB48',
  '#B9DC9D',
  '#F5C8FD',
  '#B635EA',
  '#A3E71E',
  '#C78825',
  '#9309C7',
  '#DF5FFC',
  '#56AE4E',
  '#464589',
  '#3B1B03',
  '#835454',
  '#3A26B2',
  '#6BB23C',
  '#6DA213',
  '#062F5F',
  '#CE5659',
  '#17E9C8',
  '#4EA59E',
  '#E394DF',
  '#4D9F9E',
  '#180A9A',
  '#734E92',
  '#07C069',
  '#4799D2',
  '#AA093C',
  '#D30C2C',
  '#BF1995',
  '#FE3783',
  '#B08F20',
  '#FEDD42',
  '#2BF561',
  '#35BA54',
  '#A8E0DD',
  '#72360A',
  '#3D4A6B',
  '#C28311',
  '#DE987B',
  '#B451BD',
  '#116843',
  '#9F35E7',
  '#6C5FE5',
  '#B15982',
  '#E24272',
  '#FB528C',
  '#B3A22B',
  '#622DEE',
  '#9BC497',
  '#FC439A',
  '#969091',
  '#66C8E7',
  '#E346AF',
  '#8913E6',
  '#87A63C',
  '#DD9D67',
  '#9F3952',
  '#BA2AE5',
  '#4ABA59',
  '#4A6F4F',
  '#8EFB77',
  '#669652',
  '#827BDE',
  '#5D6F20',
  '#520F26',
  '#BEFECB',
  '#DC5065',
  '#B07DD0',
  '#86D4D4',
  '#0D075B',
  '#422BFD',
  '#73A6FC',
  '#7C4845',
  '#A24FEF',
  '#02F2A6',
  '#453BBB',
  '#B9C618',
  '#A887BA',
  '#36F15E',
  '#7E920E',
  '#96B4F9',
  '#7D20AE',
  '#1581C7',
  '#4E2433',
  '#13AFB6',
  '#9E4930',
  '#13B390',
  '#178C35',
  '#56C0C8',
  '#3B4FA3',
  '#2B97B3',
  '#6A963A',
  '#DF060C',
  '#A298DC',
  '#951EC2',
  '#4FAB86',
  '#2EF848',
  '#984309',
  '#BB3CBF',
  '#FF3E85',
  '#BA39A5',
  '#AF424A',
  '#1C09DF',
  '#927945',
  '#340406',
  '#5C1C6B',
  '#5527C6',
  '#67B031',
  '#D0C6E3',
  '#439C1F',
  '#D4FDCD',
  '#2CEFEA',
  '#4DCC2E',
  '#4FC793',
  '#BAEEC4',
  '#6EB249',
  '#B7A729',
  '#C63511',
  '#5AD56B',
  '#A2E35C',
  '#E2DFCA',
  '#5D47B3',
  '#792A6B',
  '#1F5DEA',
  '#DE6E04',
  '#9D9434',
  '#D1E57D',
  '#BDF137',
  '#E24FBD',
  '#9D04DA',
  '#7FB8A1',
  '#61C891',
  '#CB9D53',
  '#AD9F04',
  '#2BF580',
  '#447029',
  '#A6CF49',
  '#01980B',
  '#A7548C',
  '#D91F31',
  '#8938A1',
  '#32E8C6',
  '#C0D1A0',
  '#C8EDCC',
  '#13751A',
  '#75849B',
  '#A10650',
  '#E5FDDD',
  '#F36B4B',
  '#48013E',
  '#8DF4C4',
  '#BF61D0',
  '#0A22CA',
  '#D15A52',
  '#2010F3',
  '#031F8F',
  '#01FEF4',
  '#E45833',
  '#3E41EF',
  '#28C4DA',
  '#E04590',
  '#1D2265',
  '#5462F6',
  '#D95DEC',
  '#2FD528',
  '#8BC8E9',
  '#904A13',
  '#CF11C2',
  '#20F0FD',
  '#850CFC',
  '#43EE06',
  '#3856E8',
  '#3376D7',
  '#0128D6',
  '#411D51',
  '#5A8958',
  '#200A44',
  '#5EBBBF',
  '#F44430',
  '#CACD9D',
  '#AA4A04',
  '#199113',
  '#141B71',
  '#55C613',
  '#3F060D',
  '#F05C0D',
  '#2A3F0C',
  '#F2A26A',
  '#7576CA',
  '#936F53',
  '#BEECDC',
  '#AB9EF3',
  '#B4BAA7',
  '#3DF211',
  '#8C498C',
  '#EA3A92',
  '#1E9B32',
  '#F4F36E',
  '#75E3EE',
  '#A6199A',
  '#5FF798',
  '#01F250',
  '#10F29E',
  '#27C3B5',
  '#258ED1',
  '#1DAA0B',
  '#5085C9',
  '#B8DEA2',
  '#F6EB1F',
  '#386E9C',
  '#DCB659',
  '#AA4CF7',
  '#A6B37A',
  '#64A4A3',
  '#CCA390',
  '#9EF63C',
  '#59433E',
  '#F70357',
  '#1F6737',
  '#B93AE6',
  '#2D01B7',
  '#507D2D',
  '#79592F',
  '#850AFD',
  '#EBDAD0',
  '#87DC12',
  '#7C46D0',
  '#469381',
  '#C7E83A',
  '#03DC8D',
  '#DD5C25',
  '#6B57E5',
  '#EBC684',
  '#699AF1',
  '#D3B987',
  '#351E96',
  '#F4B5B8',
  '#56ADE2',
  '#25B686',
  '#62313C',
  '#47D49A',
  '#23F3BE',
  '#7C25EB',
  '#51C630',
  '#1F4229',
  '#1ACD2E',
  '#031FE2',
  '#74CEF9',
  '#AB8238',
  '#DA8197',
  '#CBAEBC',
  '#99EE86',
  '#A3CEB0',
  '#E50813',
  '#AFF280',
  '#DBEE3C',
  '#AFCE9D',
  '#265FF8',
  '#3333DA',
  '#CEBA9A',
  '#6CA0FC',
  '#96E82F',
  '#326860',
  '#409804',
  '#1188A3',
  '#8529C7',
  '#DD1262',
  '#1977C2',
  '#3514A7',
  '#17C2AE',
  '#3916C7',
  '#6A2DBE',
  '#1E4B36',
  '#F72A94',
  '#32AE7B',
  '#2634F2',
  '#E4A69F',
  '#EE99A7',
  '#E0A39B',
  '#71276C',
  '#C444E2',
  '#E94946',
  '#808A62',
  '#ABE0AA',
  '#DDAC24',
  '#07CC80',
  '#2ACD5A',
  '#5C6749',
  '#3937C3',
  '#8C2040',
  '#2D5576',
  '#413AA7',
  '#ADFC97',
  '#519BD9',
  '#C89E27',
  '#96D1DC',
  '#AE45E4',
  '#861FAC',
  '#9A8049',
  '#408C50',
  '#003390',
  '#3876E4',
  '#1B3D9C',
  '#A4B3BA',
  '#A09565',
  '#DC7B8B',
  '#C9B443',
  '#6EFB10',
  '#05A48B',
  '#3F4E74',
  '#B7CB8E',
  '#D60DA5',
  '#AEE928',
  '#0AA684',
  '#CA41B1',
  '#56D340',
  '#13A767',
  '#F69927',
  '#C4515B',
  '#00068B',
  '#D8B008',
  '#6CF928',
  '#3605D6',
  '#8C7040',
  '#A7BEE7',
  '#8E175B',
  '#D6272A',
  '#E2BFC0',
  '#4900AF',
  '#9F3467',
  '#6DD9D1',
  '#8FD5E6',
  '#2FA096',
  '#0D4A21',
  '#563261',
  '#849BA8',
  '#B766D1',
  '#EE959D',
  '#F6AF83',
  '#1FE6A2',
  '#75A0F2',
  '#401B4D',
  '#4F70A8',
  '#A99ADB',
  '#DAEA3B',
  '#EFD48F',
  '#3F6815',
  '#67F984',
  '#5CAA2D',
  '#F0ADA3',
  '#7F6052',
  '#4812DD',
  '#95D83A',
  '#58A2FF',
  '#57C4F0',
  '#29FD49',
  '#5015E2',
  '#F27936',
  '#9E44EE',
  '#D87852',
  '#B659E7',
  '#EC37A3',
  '#F4DE1C',
  '#5AAE3F',
  '#5D67E8',
  '#000CF7',
  '#875814',
  '#D25D2C',
  '#6C7819',
  '#3AC9A7',
  '#E3F3B5',
  '#01DC61',
  '#35B0ED',
  '#858B6E',
  '#D25208',
  '#EB5EF1',
  '#4D302E',
  '#E011AC',
  '#23C295',
  '#4BBC7F',
  '#A3F0E1',
  '#494D7E',
  '#E65272',
  '#8EC863',
  '#BF366E',
  '#F9FDDA',
  '#C1B9A7',
  '#DF6612',
  '#F9A6B8',
  '#9C57A4',
  '#B9123B',
  '#1858E0',
  '#535551',
  '#DAE3D6',
  '#530C66',
  '#D455B8',
  '#7BF2D4',
  '#0B574B',
  '#BE3944',
  '#C80152',
  '#75CD50',
  '#6F28D6',
  '#092020',
  '#E59A62',
  '#5DFE87',
  '#0E4883',
  '#A160D6',
  '#D34A33',
  '#C001CD',
  '#CDE22E',
  '#97D023',
  '#1A3E2C',
  '#6A0F43',
  '#89BBCA',
  '#9F0E09',
  '#E83653',
  '#4C21DA',
  '#884C8E',
  '#3CDA41',
  '#011F6B',
  '#5F1C44',
  '#11C2A3',
  '#D21DE7',
  '#49CD9B',
  '#468F8A',
  '#048995',
  '#197E92',
  '#7A7BA6',
  '#8E3C0E',
  '#30A0DF',
  '#BD1378',
  '#318979',
  '#84620A',
  '#AF47D4',
  '#34721D',
  '#DB8106',
  '#29A811',
  '#9F699F',
  '#54B572',
  '#489D68',
  '#8B40A3',
  '#A50398',
  '#6969DF',
  '#7ED311',
  '#996225',
  '#9FC348',
  '#FABFD4',
  '#091954',
  '#228629',
  '#0E420D',
  '#BAA4AA',
  '#594A57',
  '#15D7F5',
  '#F4B6D8',
  '#03465F',
  '#58D746',
  '#4624FB',
  '#F09369',
  '#6080F4',
  '#1ABB09',
  '#B492DC',
  '#396024',
  '#FAE78A',
  '#891060',
  '#038E72',
  '#36F4A3',
  '#40B6B4',
  '#197C8B',
  '#60A56F',
  '#7FB69D',
  '#0C2DB0',
  '#44344A',
  '#A76C58',
  '#3C71D7',
  '#25308C',
  '#A6DCBB',
  '#5010D8',
  '#4CE02F',
  '#7BD6BF',
  '#BCD8C7',
  '#F44400',
  '#4E32A2',
  '#E749F5',
  '#C53C80',
  '#A450F7',
  '#526031',
  '#AA5B34',
  '#FC41D9',
  '#EB1ABF',
  '#D29981',
  '#FD621A',
  '#B79A75',
  '#2FCEBB',
  '#5F14E7',
  '#095A8F',
  '#4F12E2',
  '#FABC87',
  '#6119D4',
  '#7CEDDA',
  '#2ACBE2',
  '#14533B',
  '#A9DDDD',
  '#3584AA',
  '#FA6E1B',
  '#B22FF5',
  '#6431F3',
  '#D98AB0',
  '#53B724',
  '#0E90EA',
  '#2750AA',
  '#A5CEC3',
  '#0E67F0',
  '#74A5C4',
  '#177C74',
  '#4A8B66',
  '#676474',
  '#39BE3A',
  '#32CD3B',
  '#87E196',
  '#6FEEC7',
  '#29E44E',
  '#C4A4F3',
  '#B2085C',
  '#153729',
  '#727946',
  '#97AE8F',
  '#755DDC',
  '#4EA17E',
  '#7B5EA6',
  '#AD319A',
  '#FA52FD',
  '#0A134C',
  '#86CE88',
  '#2479E6',
  '#83C3C3',
  '#70DE1D',
  '#74B6D8',
  '#4C53AB',
  '#2F4542',
  '#2220EC',
  '#0DCBD1',
  '#8D4F92',
  '#DCDFE3',
  '#B0E153',
  '#467D54',
  '#B855FA',
  '#76F3F3',
  '#DA5028',
  '#3BC16B',
  '#4D0BDF',
  '#E3D9DB',
  '#DAD983',
  '#FB645D',
  '#E503F9',
  '#C4BAB7',
  '#A425C3',
  '#1E8876',
  '#8AE717',
  '#1E4F3C',
  '#6B5AA5',
  '#CD2EB2',
  '#3B4181',
  '#470874',
  '#32D54B',
  '#842214',
  '#BBC345',
  '#A9B9EC',
  '#425BF2',
  '#8AA853',
  '#C5BCF7',
  '#F4AD04',
  '#3E073A',
  '#CA622C',
  '#864931',
  '#6A2684',
  '#9C1230',
  '#538ABE',
  '#34DA97',
  '#8320B8',
  '#C5725A',
  '#C547FB',
  '#984478',
  '#D2E7D3',
  '#747EA0',
  '#A18156',
  '#195C29',
  '#6812C8',
  '#0019BA',
  '#102034',
  '#ECBA39',
  '#1E2E48',
  '#3F005C',
  '#AE6187',
  '#9DFF86',
  '#EEF9E3',
  '#4A9A84',
  '#396168',
  '#710CC3',
  '#DF8762',
  '#FE8DA5',
  '#205906',
  '#B41D3A',
  '#1DBCEB',
  '#A1BAA6',
  '#501A94',
  '#82812E',
  '#61B358',
  '#8996D2',
  '#BCD7BB',
  '#2CD4B3',
  '#566E81',
  '#F41147',
  '#89CD61',
  '#83241C',
  '#78BA13',
  '#C06B14',
  '#53CEE6',
  '#3B6B5A',
  '#535777',
  '#54C682',
  '#39D9A9',
  '#9911AA',
  '#0FE273',
  '#0CBDCF',
  '#02711C',
  '#CFAD8D',
  '#6A2ED8',
  '#7671E3',
  '#DC1BEA',
  '#9B57B6',
  '#881498',
  '#1A80D9',
  '#B919A9',
  '#A6AC01',
  '#776715',
  '#67CFE4',
  '#BF2043',
  '#95B001',
  '#E0824B',
  '#947B66',
  '#0F9159',
  '#B1099F',
  '#D7A997',
  '#C20933',
  '#017F67',
  '#64D3E4',
  '#AFE250',
  '#15C9CF',
  '#D7C0C4',
  '#C7BDE9',
  '#09A756',
  '#AD6E40',
  '#F4BC25',
  '#D83F47',
  '#0B84F9',
  '#3E0CBA',
  '#919CE1',
  '#D3541B',
  '#9FDA3E',
  '#B082FB',
  '#8233FB',
  '#8D39CB',
  '#2335A5',
  '#7EB449',
  '#678264',
  '#AFCDBF',
  '#67BC0A',
  '#BB4509',
  '#1614DF',
  '#955814',
  '#DB40DA',
  '#330CBA',
  '#B1C419',
  '#A11F9D',
  '#E59C0A',
  '#80F30E',
  '#95865A',
  '#4FEFA8',
  '#63C81F',
  '#D21577',
  '#6FF310',
  '#70A448',
  '#5925CB',
  '#158198',
  '#DB5F74',
  '#DCACC9',
  '#B93AE1',
  '#024AB2',
  '#391B6B',
  '#A1F2AA',
  '#5174F8',
  '#86632D',
  '#81AE9C',
  '#1E2285',
  '#58FA63',
  '#DCB647',
  '#61998B',
  '#4C83C8',
  '#AE67D1',
  '#0E8B6E',
  '#6F55EC',
  '#DCFCA5',
  '#713EF4',
  '#EBE639',
  '#043ABB',
  '#0D3465',
  '#892CDE',
  '#51F713',
  '#F03CE1',
  '#6EF253',
  '#3C3D4E',
  '#EAAD73',
  '#64D638',
  '#C3CFDA',
  '#EC5ED1',
  '#C70075',
  '#EE1D2D',
  '#8E3687',
  '#6ED371',
  '#81EDC9',
  '#E5C4D7',
  '#AD6BB8',
  '#62B412',
  '#910B13',
  '#E41A89',
  '#90040B',
  '#586536',
  '#1CDD77',
  '#9C9CFB',
  '#7A0DB4',
  '#A39ED8',
  '#A54673',
  '#A8C9F9',
  '#5F843B',
  '#D14AB2',
  '#B8D2EE',
  '#01B2B7',
  '#1A5851',
  '#58EE33',
  '#D0C3D3',
  '#60CCE3',
  '#914A8B',
  '#54250E',
  '#18F532',
  '#4791FF',
  '#2E7B7C',
  '#29B8EA',
  '#4060CF',
  '#1E9B02',
  '#FB24BD',
  '#376C35',
  '#639D67',
  '#84D78E',
  '#5EAC97',
  '#8DB54E',
  '#9FB058',
  '#97F850',
  '#2F4FEC',
  '#A74855',
  '#85367C',
  '#3A6E17',
  '#872929',
  '#DD3D4E',
  '#BA69C1',
  '#1A29DD',
  '#1D0D68',
  '#92EA22',
  '#B0825A',
  '#FFEA56',
  '#A1D6C7',
  '#A4405A',
  '#BBB0C0',
  '#0C4B05',
  '#609AE9',
  '#1BC8B7',
  '#A73685',
  '#154C79',
  '#74959D',
  '#8A6CCF',
  '#8B2EDB',
  '#D9BF21',
  '#F37054',
  '#B2EF39',
  '#02C650',
  '#9F0F6C',
  '#7769E9',
  '#8CCA02',
  '#901541',
  '#A167CF',
  '#00D61C',
  '#78B1A6',
  '#76BD39',
  '#6A403D',
  '#C1A1B6',
  '#29172B',
  '#BA9CDB',
  '#4DD3F3',
  '#E3CD51',
  '#F56324',
  '#285B21',
  '#D85BBA',
  '#8713B1',
  '#F3E2A3',
  '#601E55',
  '#E82292',
  '#887169',
  '#F743A4',
  '#6D53E5',
  '#9AA05F',
  '#9E06B0',
  '#5A8F67',
  '#868F07',
  '#0F7645',
  '#6CE59D',
  '#D8CAD2',
  '#537B22',
  '#E34CB1',
  '#81F9E5',
  '#7810C1',
  '#F1A98E',
  '#D75ADE',
  '#0AB0F7',
  '#A25290',
  '#B48902',
  '#8E0791',
  '#DD41D7',
  '#4EEB31',
  '#950470',
  '#109E2E',
  '#EF5EBC',
  '#230457',
  '#83C030',
  '#64F22A',
  '#B2079A',
  '#BAF9A5',
  '#44E8A5',
  '#717A58',
  '#EC9517',
  '#8CC749',
  '#8D9112',
  '#EF6057',
  '#1CFE60',
  '#C6EA56',
  '#D35F1F',
  '#71E839',
  '#118644',
  '#35862F',
  '#798221',
  '#B4B11D',
  '#3CA2D0',
  '#943049',
  '#0C7443',
  '#E900E2',
  '#9C6193',
  '#607223',
  '#216142',
  '#0FC638',
  '#30ADB2',
  '#4436E9',
  '#C34FE7',
  '#BC9AE4',
  '#008372',
  '#40EBC0',
  '#BF5864',
  '#08392E',
  '#E3BE04',
  '#EB386A',
  '#2261D5',
  '#E5C6B9',
  '#56DAF4',
  '#61AE2B',
  '#B23C05',
  '#28A144',
  '#9506A3',
  '#8F1DA3',
  '#4126A7',
  '#35081C',
  '#F759CD',
  '#8E41B5',
  '#E45106',
  '#45EEC4',
  '#6CFC45',
  '#92B8FD',
  '#67F0E2',
  '#E0DCA1',
  '#C5F181',
  '#1E0AB5',
  '#36C829',
  '#37BDB8',
  '#B26ABD',
  '#9F40F3',
  '#5F91C7',
  '#0A1723',
  '#3A1B40',
  '#5D5AE6',
  '#2ED0F8',
  '#E03DD0',
  '#B98EFA',
  '#FE9751',
  '#F594DF',
  '#C30C42',
  '#19FEB2',
  '#B5C860',
  '#74A51B',
  '#D53022',
  '#79C40B',
  '#089BD0',
  '#BAB600',
  '#1D017E',
  '#53DBDA',
  '#013FFE',
  '#256562',
  '#AC9A5B',
  '#C361AF',
  '#2C467B',
  '#C32C94',
  '#547C8B',
  '#B22D84',
  '#C9F91A',
  '#113B48',
  '#1F3819',
  '#0B262A',
  '#46B6CE',
  '#2DC304',
  '#0BA616',
  '#70275F',
  '#2218E1',
  '#A738A3',
  '#F17537',
  '#B2CA39',
  '#6E7311',
  '#D977C5',
  '#FDA106',
  '#935854',
  '#018BF0',
  '#B18CCC',
  '#01DFF4',
  '#6CCE75',
  '#4C2EEA',
  '#43CF69',
  '#013F4A',
  '#39DAEC',
  '#7A8A84',
  '#DF99FC',
  '#8F986D',
  '#3895FA',
  '#A5ABFF',
  '#B7F2F4',
  '#571E41',
  '#3FAA24',
  '#FA1FFA',
  '#914581',
  '#1558AF',
  '#9B2A7E',
  '#FB56A0',
  '#B9807F',
  '#DF96E9',
  '#0DDE1A',
  '#D94B55',
  '#7B1E13',
  '#084B0B',
  '#21D3D2',
  '#EF8228',
  '#4121E0',
  '#511A71',
  '#95004E',
  '#FB2E1B',
  '#BA37E5',
  '#4C3F9D',
  '#E6FF24',
  '#9AA379',
  '#30CC16',
  '#485ED9',
  '#F77E54',
  '#CE5C1E',
  '#68CA04',
  '#785AAA',
  '#F60559',
  '#55ACC5',
  '#49A58D',
  '#F7D6CB',
  '#13A529',
  '#880A78',
  '#F3948C',
  '#366A4E',
  '#D35D50',
  '#C8256D',
  '#28AC4D',
  '#DE76E9',
  '#418863',
  '#066BCE',
  '#DFE17E',
  '#31D291',
  '#0103B9',
  '#7BEA67',
  '#B5C690',
  '#929363',
  '#F9EAFF',
  '#F4305B',
  '#0ABB2A',
  '#F7D0E5',
  '#43DACC',
  '#FC3099',
  '#1AC1B5',
  '#8959E4',
  '#FA15EA',
  '#0C405F',
  '#FC80D4',
  '#1702E3',
  '#2B3A33',
  '#74AB99',
  '#9D1C47',
  '#8EDB0D',
  '#45D0B1',
  '#85B7AD',
  '#2DF08B',
  '#9E2109',
  '#C5FCA9',
  '#9DA048',
  '#C3CBCA',
  '#2CA785',
  '#93239B',
  '#88F7A7',
  '#CE0769',
  '#F5F8DC',
  '#EB7B16',
  '#F5E4F5',
  '#52CEDD',
  '#DE2B73',
  '#3FEF20',
  '#612D0F',
  '#8CBDE7',
  '#1E9062',
  '#C4CBED',
  '#44FBB3',
  '#9C9D69',
  '#B45038',
  '#3C3F1B',
  '#2C6F6B',
  '#51C3C0',
  '#B35D15',
  '#A242B4',
  '#410CC0',
  '#2571B2',
  '#C2867C',
  '#90D3E6',
  '#0C29AA',
  '#780313',
  '#72A1B5',
  '#AF042D',
  '#438918',
  '#2113F7',
  '#D7A571',
  '#478B02',
  '#BB68A9',
  '#C0E2B8',
  '#116D2C',
  '#514C83',
  '#E60393',
  '#10E2C3',
  '#D63D5F',
  '#803676',
  '#04D854',
  '#77AD18',
  '#20B937',
  '#FBE60E',
  '#7FA64C',
  '#8C1A6C',
  '#2ED7C0',
  '#3C38FB',
  '#730C7D',
  '#12E7F0',
  '#C84C9C',
  '#1791A2',
  '#C22439',
  '#88ED4A',
  '#43F562',
  '#891D3B',
  '#129280',
  '#14E195',
  '#38ADFC',
  '#835F71',
  '#37348F',
  '#40E664',
  '#F0E75A',
  '#2E7C75',
  '#0843A7',
  '#8A1998',
  '#7643A6',
  '#AE5018',
  '#DC9237',
  '#7AB532',
  '#79915A',
  '#5CA8DF',
  '#596063',
  '#7A0863',
  '#4D6D0E',
  '#A33420',
  '#5EFE00',
  '#68BDE2',
  '#D1CA10',
  '#C0319B',
  '#CAE618',
  '#A80C92',
  '#F0201B',
  '#4BD78E',
  '#F7DBD1',
  '#318A1E',
  '#53C0B4',
  '#622B9B',
  '#5987FA',
  '#2C65E0',
  '#F6B6C4',
  '#6964E4',
  '#AD2E43',
  '#656BA6',
  '#5AD888',
  '#C2D442',
  '#E5C2D5',
  '#95F9E8',
  '#526A7B',
  '#623723',
  '#9816F5',
  '#C2D97F',
  '#AD548D',
  '#BA5E5D',
  '#987074',
  '#0DF09C',
  '#A733A2',
  '#E0F042',
  '#A5A2DC',
  '#CB4A9D',
  '#953024',
  '#D30F10',
  '#998EBA',
  '#F1226C',
  '#EB49C7',
  '#2D475A',
  '#92F728',
  '#09F545',
  '#A406A8',
  '#FA50B5',
  '#2DF052',
  '#60E16C',
  '#483669',
  '#2F906B',
  '#DA5A6B',
  '#4340F5',
  '#4F634D',
  '#E478B3',
  '#7A9B30',
  '#A444CE',
  '#9AE10E',
  '#95C4E2',
  '#E672B7',
  '#01126D',
  '#868DFE',
  '#52C7BB',
  '#1E3537',
  '#7E2623',
  '#E32469',
  '#65E16B',
  '#0CCEFC',
  '#CD10BB',
  '#26EB41',
  '#A17756',
  '#116435',
  '#AEC3EE',
  '#82E240',
  '#D08AA4',
  '#EACD60',
  '#633973',
  '#F54BC3',
  '#21115B',
  '#217222',
  '#2ED382',
  '#6217CE',
  '#EF981E',
  '#3FA293',
  '#9DF0E6',
  '#E60258',
  '#D2CE4F',
  '#56CEA3',
  '#7B5955',
  '#96FFC2',
  '#E93B3A',
  '#48B993',
  '#613C1E',
  '#7CB848',
  '#15D7C3',
  '#09B572',
  '#A00579',
  '#ED6DF9',
  '#8AF5D2',
  '#546816',
  '#DD8D99',
  '#BC8DEB',
  '#443C57',
  '#F92217',
  '#9B495D',
  '#D67718',
  '#1637EB',
  '#AF2CCC',
  '#14B5D0',
  '#BE5026',
  '#C9E9C6',
  '#7F3A54',
  '#890483',
  '#4E3F44',
  '#53CCF3',
  '#E72495',
  '#7615C2',
  '#D57192',
  '#12A2C9',
  '#95ED7F',
  '#EF1FF7',
  '#DAD408',
  '#1D4A05',
  '#C52EE6',
  '#0A9D2F',
  '#B33A3E',
  '#1D817E',
  '#0F18F7',
  '#F5CD2E',
  '#16C1C6',
  '#0BD056',
  '#D16DA4',
  '#4EFA36',
  '#A29736',
  '#759BED',
  '#2C27FD',
  '#45330B',
  '#DC63D0',
  '#AF6C1F',
  '#917FC0',
  '#C0B5A7',
  '#64A5B1',
  '#A55B37',
  '#96E81C',
  '#6D0501',
  '#ACD76A',
  '#B0530E',
  '#EAD080',
  '#4AE073',
  '#E9A2C0',
  '#628490',
  '#8A84BC',
  '#61C789',
  '#77581E',
  '#76428F',
  '#25D57B',
  '#69C06E',
  '#DF54A1',
  '#CB36A8',
  '#211330',
  '#DF3ECA',
  '#135B43',
  '#30FE00',
  '#16A273',
  '#091873',
  '#795532',
  '#2B4511',
  '#1B44D2',
  '#2A501B',
  '#71C7CB',
  '#262D5E',
  '#DA76E6',
  '#B3FCD7',
  '#89C23A',
  '#CAD169',
  '#68D5C7',
  '#E44566',
  '#CB12D7',
  '#506E9D',
  '#CE11BB',
  '#DB7D58',
  '#EF12FC',
  '#E85131',
  '#3927B3',
  '#4DBD04',
  '#76B7A7',
  '#D8F2A7',
  '#DE87C8',
  '#0B9385',
  '#544601',
  '#4FFF38',
  '#2FE140',
  '#E02B2D',
  '#C4DC28',
  '#B7786C',
  '#D0D65E',
  '#9C9365',
  '#8F3DB0',
  '#B7FF78',
  '#1C862B',
  '#B49409',
  '#DCC7F8',
  '#CEAC5A',
  '#E7965C',
  '#DA348C',
  '#3FCC2F',
  '#1CECE3',
  '#DDDC2A',
  '#53777E',
  '#3800E9',
  '#73C692',
  '#E99F3B',
  '#7A7331',
  '#EEFF6E',
  '#A9B392',
  '#6BBDD8',
  '#5FCA36',
  '#331265',
  '#A2C6C1',
];
